<template>
  <div class="SubsectionTitle">
    <span class="componentLabel">
      {{ componentLabel }}
    </span>
    <span> - </span>
    <span class="componentLocationLabel">
      {{ componentLocationLabel }}
    </span>
  </div>
</template>

<script>

export default {
  inject: [ 'store' ],
  props: {
    component: {
      type: String,
      default: undefined
    },
    componentLocation: {
      type: String,
      default: undefined
    }
  },
  computed: {
    componentLabel () {
      return this.store.components.formatComponent(this.component)
    },
    componentLocationLabel () {
      return this.store.components.formatComponentLocation(this.componentLocation)
    }
  }
}
</script>

<style lang="scss">
.SubsectionTitle {
  .componentLabel {
    text-transform: uppercase;
  }

  .componentLocationLabel {
    text-transform: capitalize;
  }
}
</style>
