<template>
  <div class="ChooseSections">
    <h4 class="instructions">
      Select the Development BOM section(s)
      you would like to apply to the PC9 BOM(s)
    </h4>

    <div
      v-if="store.bomEditor.count"
      class="lists"
    >
      <CheckOptions
        :options="sectionOptions"
        :model="assign.chooseSections"
        name="selectedSections"
      />
    </div>

    <div
      v-if="assign.chooseSections.showErrors"
      class="error"
    >
      Please choose at least one section.
    </div>

    <div class="footer">
      <ModalLink
        name="assign-bom-pc9-results"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div class="ModalButtonsWrapper">
        <SelectAllLink
          :is-deselect-mode="assign.chooseSections.allSelected"
          :on-select-all="assign.chooseSections.selectAll"
          :on-deselect-all="assign.chooseSections.deselectAll"
        />
        <a
          class="button"
          @click="onReviewClick"
        >
          Review
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BackLink from '../BackLink.vue'
import CheckOptions from '../CheckOptions.vue'
import ModalLink from '../ModalLink.vue'
import SelectAllLink from '../SelectAllLink.vue'

export default {
  components: {
    BackLink,
    CheckOptions,
    ModalLink,
    SelectAllLink
  },
  inject: ['store'],
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { params } = this.store.modals
      return {
        ...params
      }
    },
    reviewLinkParams () {
      const { assign } = this.store
      const { params } = this.store.modals
      return {
        ...params,
        sections: assign.chooseSections.selectedSections
      }
    },
    sectionOptions () {
      return this.assign.chooseSections.sectionOptions
    }
  },
  mounted () {
    this.assign.chooseSections.showErrors = false
  },
  methods: {
    onReviewClick () {
      const { assign, modals } = this.store
      if (assign.chooseSections.isValid) {
        modals.show('assign-bom-review', this.reviewLinkParams)
      } else {
        assign.chooseSections.showErrors = true
      }
    }
  }
}
</script>

<style lang="scss">
.ChooseSections {
  .instructions {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .CheckOptions {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;

        .icon {
          display: inline-block;
          margin-left: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .error {
    color: $red;
    text-align: right;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
}
</style>
