<template>
  <div
    v-observe-visibility="visibilityChanged"
    class="BomReviewComponent"
  >
    <SubsectionTitle
      :component="component.component"
      :component-location="component.componentLocation"
    />
    <div v-if="isVisible">
      <PreviewLineItem
        v-for="(lineItem, index) in component.lineItemDetails"
        :key="index"
        :line-item="lineItem"
        :show-card="false"
      />
    </div>
  </div>
</template>

<script>
import PreviewLineItem from '../CreateDevBom/PreviewLineItem.vue'
import SubsectionTitle from '../SubsectionTitle.vue'

export default {
  components: {
    PreviewLineItem,
    SubsectionTitle
  },
  props: {
    component: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    }
  }
}
</script>

<style lang="scss">
.BomReviewComponent {
  background-color: $gray-component-background;
  padding: 2rem;
  margin-bottom: 1rem;

  .SubsectionTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2rem;
    color: $gray;
  }

  .BomReviewLineItem {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
</style>
