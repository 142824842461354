<template>
  <select
    v-model="value"
    class="ComponentDropdown"
  >
    <option
      v-for="component in componentOptions"
      :key="component.value"
      :value="component.value"
    >
      <span>{{ component.label }}</span>
    </option>
  </select>
</template>

<script>
export default {
  inject: [ 'store' ],
  props: {
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    componentOptions () {
      return this.store.subsections[this.sectionName].components
    },
    value: {
      get () {
        return this.model[this.name]
      },
      set (value = '') {
        this.model[this.name] = value
      }
    }
  },
  watch: {
    value (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss">
.ComponentDropdown {
  text-transform: uppercase;
}
</style>
