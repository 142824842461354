<template>
  <div class="FindMaterial">
    <div
      v-if="store.findMaterial.isOpen"
      v-hotkey="keymap"
      class="paneSlider"
      :class="currentPane"
    >
      <div class="pane">
        <FindMaterialChooseCodePane
          ref="chooseCodePane"
          :section-name="sectionName"
        />
      </div>
      <div class="pane">
        <FindMaterialColorSupplierPane :section-name="sectionName" />
      </div>
    </div>
  </div>
</template>

<script>
import FindMaterialChooseCodePane from './FindMaterialChooseCodePane.vue'
import FindMaterialColorSupplierPane from './FindMaterialColorSupplierPane.vue'

export default {
  components: {
    FindMaterialChooseCodePane,
    FindMaterialColorSupplierPane
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      default: 'Material'
    }
  },
  computed: {
    currentPane () {
      return `${this.store.findMaterial.pane}`
    },
    keymap () {
      return {
        scope: 'findMaterial',
        keys: {
          esc: this.store.findMaterial.close,
          'ctrl + b': this.store.findMaterial.previousPane,
          'cmd + b': this.store.findMaterial.previousPane
        }
      }
    }
  }
}
</script>

<style lang="scss">
.FindMaterial {
  overflow: hidden;

  .paneSlider {
    width: 200%;
    display: flex;
    padding-top: 1.5rem;
    transition: transform 300ms ease;

    .pane {
      width: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0 0.5rem;
    }

    &.materialCode {
      transform: translateX(0%);
    }

    &.colorSupplier {
      transform: translateX(-50%);
    }
  }

  .buttons {
    display: flex;
    padding: 1rem 0;
    justify-content: flex-end;
  }
}
</style>
