<template>
  <div
    class="AddMaterial"
    @click.stop
  >
    <form @submit.prevent="onSubmit">
      <div>
        <label>Quantity *</label>
        <input
          v-model.number="quantity"
          type="text"
        >
        <div
          v-if="showError"
          class="error"
        >
          Quantity is required
        </div>
      </div>

      <div class="remarksField">
        <label>Remarks</label>
        <input
          v-model="remarks"
          type="text"
        >
      </div>
    </form>
  </div>
</template>

<script>
import isNumber from 'is-number'

export default {
  inject: [ 'store' ],
  props: {
    lineItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showError: false
    }
  },
  computed: {
    quantity: {
      get () {
        return this.lineItem.quantity
      },
      set (value) {
        this.lineItem.quantity = value
      }
    },
    remarks: {
      get () {
        return this.lineItem.lscoRemarks
      },
      set (value) {
        this.lineItem.lscoRemarks = value
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.validate()) {
        this.store.confirmationDialog.confirm()
      }
    },
    validate () {
      const isValid = isNumber(this.lineItem.quantity)
      this.showError = !isValid
      return isValid
    }
  }
}
</script>

<style lang="scss">
.AddMaterial {
  padding: 1rem 4rem;
  min-width: 640px;

  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;

    input {
      padding: 0.5rem 1rem;
    }

    .remarksField {
      grid-column-end: span 2;
    }
  }

  .error {
    color: $red;
    margin-top: 0.5rem;
  }
}
</style>
