<template>
  <div
    v-if="!showError"
    class="DevBomConfirmRemoval"
  >
    <div class="operationDescription">
      <span class="bomDescription">{{ bomDescription }}</span>
      <span> will be removed.</span>
    </div>
    <div class="action-block">
      <button
        class="button cancel"
        @click="onCancelClick"
      >
        Cancel
      </button>
      <button
        class="button remove"
        @click="onConfirmClick"
      >
        Remove
      </button>
    </div>
  </div>
  <div
    v-else
    class="error"
  >
    <h4>We're sorry, the BOM could not be removed.</h4>
    <h4>Please try again later.</h4>
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  data () {
    return {
      showError: false
    }
  },
  computed: {
    bomDescription () {
      if (this.boms.length === 1) {
        return this.boms[0].title || 'Untitled'
      } else {
        return `${this.boms.length} BOMs`
      }
    },
    bomIds () {
      return this.boms.map((bom) => {
        return bom.bompartId
      })
    },
    boms () {
      return this.store.modals.params.boms || []
    },
    redirect () {
      return this.store.modals.params.redirect
    }
  },
  methods: {
    async deleteBoms () {
      try {
        this.showError = false
        await this.store.devBomApi.deleteBoms({
          bomIds: this.bomIds
        })
        this.store.clickStream.obEvent('Delete BOM', 'Removing the BOM')
        this.refresh()
      } catch (error) {
        this.showError = true
        this.store.clickStream.obEvent('Error', error.message)
      }
    },
    onCancelClick () {
      this.store.modals.hide()
    },
    onConfirmClick () {
      this.deleteBoms()
    },
    refresh () {
      if (this.redirect) {
        this.store.router.replace(this.redirect)
      } else {
        this.store.devBoms.clearSelection()
        this.store.devBoms.fetch()
        this.store.modals.hide()
      }
    }
  }
}
</script>

<style lang="scss">
.DevBomConfirmRemoval {
  .bomDescription {
    color: $blue;
    font-style: italic;
  }

  .error {
    color: $red;
  }
}
</style>
