<template>
  <div class="FindMaterialFabricSearch">
    <div class="row">
      <div class="col">
        <label>Fabric Type</label>
        <select v-model="fabricType">
          <option
            v-for="option in fabricTypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div class="col">
        <label>Fabric Subtype</label>
        <select v-model="fabricSubtype">
          <option
            v-for="option in fabricSubtypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <label>Branded Content</label>
        <select v-model="brandedContent">
          <option
            v-for="option in brandedContentOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div class="col">
        <label>Generic Content</label>
        <select v-model="genericContent">
          <option
            v-for="option in genericContentOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="fabricSearchControls">
      <button
        class="button"
        @click="onFabricSearchClick"
      >
        Search
      </button>
    </div>
  </div>
</template>

<script>
const anyOption = {
  value: '',
  label: 'Any'
}

export default {
  inject: [ 'store' ],
  computed: {
    brandedContent: {
      get () {
        return this.model.brandedContent
      },
      set (value) {
        this.model.brandedContent = value
      }
    },
    brandedContentOptions () {
      return [ anyOption, ...this.store.codes.brandedContent ]
    },
    fabricSubtype: {
      get () {
        return this.model.fabricSubtype
      },
      set (value) {
        this.model.fabricSubtype = value
      }
    },
    fabricSubtypeOptions () {
      return [ anyOption, ...this.store.codes.fabricSubtype ]
    },
    fabricType: {
      get () {
        return this.model.fabricType
      },
      set (value) {
        this.model.fabricType = value
      }
    },
    fabricTypeOptions () {
      return [ anyOption, ...this.store.codes.fabricType ]
    },
    genericContent: {
      get () {
        return this.model.genericContent
      },
      set (value) {
        this.model.genericContent = value
      }
    },
    genericContentOptions () {
      return [ anyOption, ...this.store.codes.genericContent ]
    },
    model () {
      return this.store.chooseMaterialCode
    }
  },
  methods: {
    onFabricSearchClick () {
      const { chooseMaterialCode } = this.store
      chooseMaterialCode.skip = 0
      chooseMaterialCode.fetch()
    }
  }
}
</script>

<style lang="scss">
.FindMaterialFabricSearch {
  .row {
    display: flex;
    margin-top: 1rem;

    .col {
      flex-basis: 50%;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .fabricSearchControls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
</style>
