<template>
  <div
    v-if="section.section"
    class="PreviewSection"
  >
    <h3 class="sectionHeader">
      {{ sectionLabel }}
    </h3>
    <PreviewComponent
      v-for="component in section.section.components"
      :key="component.subsection"
      :component="component"
    />
  </div>
</template>

<script>
import PreviewComponent from './PreviewComponent.vue'

export default {
  components: {
    PreviewComponent
  },
  inject: [ 'store' ],
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    sectionLabel () {
      return this.store.codes.sections[this.section.sectionName]
    }
  }
}
</script>

<style lang="scss">
.PreviewSection {
  margin-bottom: 4rem;

  .sectionHeader {
    margin-bottom: 1rem;
  }
}
</style>
