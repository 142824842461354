<template>
  <li
    class="BomSidebarSection"
    :class="sectionName"
  >
    <router-link
      :to="linkUrl"
    >
      <img
        class="icon"
        :src="iconSrc"
      >

      <span class="label">
        {{ label }}
      </span>

      <span
        class="selector"
      >
        <span
          class="indicator"
          :class="{ active: isActive }"
        />
      </span>
    </router-link>
  </li>
</template>

<script>
import queryString from 'query-string'

export default {
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    currentSection () {
      return this.$route.params.currentSection
    },
    isActive () {
      return this.currentSection === this.sectionName
    },
    label () {
      return this.store.codes.sections[this.sectionName]
    },
    count () {
      return this.store.bomEditor[this.sectionName].components.length
    },
    iconSrc () {
      return `/images/ico-${this.sectionName}.svg`
    },
    linkUrl (section) {
      const path = `./${this.sectionName}`

      const { router } = this.store
      const { selectedBoms } = router.hash

      const hash = queryString.stringify({ selectedBoms })

      return {
        path,
        query: router.query,
        hash
      }
    }
  }
}
</script>

<style lang="scss">
.BomSidebarSection {
  color: $gray;

  &:hover {
    opacity: 0.6;
  }

  a {
    color: $gray;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;

    .icon {
      padding-right: 2rem;
    }

    .label {
      flex-grow: 2;
      padding-right: 2rem;
    }

    .count {
      padding-right: 1rem;
    }

    .needsAttention {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 1rem;

      &.active {
        background-color: $red;
      }
    }

    .selector {
      width: 1px;
      align-self: stretch;

      .indicator {
        transition: all 0.25s;
        display: block;
        height: 0;
        width: 100%;
        position: relative;
        right: -1px;
        top: 50%;
        background-color: transparent;
        opacity: 0;
      }

      .indicator.active {
        height: calc(100% + 24px);
        background-color: $gray;
        top: calc(0% - 12px);
        opacity: 1;
      }
    }
  }
}
</style>
