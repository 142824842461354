<template>
  <drop
    class="MaterialBomItem"
    :class="{ dropTarget: isDropTarget }"
    :style="[isMRPlock && isPowerCore ? { border: '1px solid #c41230 !important'} : isMRPlock && !isPowerCore ? { border: '2px solid #ebeced !important' } : isPowerCore ? { border: '1px solid #c41230 !important'} : null]"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <router-link
      v-if="isVisible"
      :to="bomLinkParams"
    >
      <div class="ColorwayNameColorCodeWrapper">
        <MaterialBomColorCode
          :seasonal-look-id="bom.seasonalLookId"
          :prod-type="bom.prodType"
          :component="component"
        />
        <span
          class="colorwayName"
          :title="bom.colorwayName"
        >{{ bom.colorwayName }}
        </span>
      </div>

      <MaterialBomContextMenu
        v-if="allowEdit && !isMRPlock && !isSeasonal && !isPowerCore"
        :line-item="lineItem"
        :bom="bom"
      />
    </router-link>

    <DropZone
      v-if="isVisible"
      :is-showing="isDropTarget"
      mode="overlay"
    >
      <span v-if="noMaterial">Add Material</span>
      <span v-else>Replace Material</span>
    </DropZone>
  </drop>
</template>

<script>
import AddMaterial from './dialogs/AddMaterial.vue'
import ConfirmReplacement from './dialogs/ConfirmReplacement.vue'
import AlertMessage from './dialogs/AlertMessage.vue'
import DropZone from '../DropZone.vue'
import isElementNested from '../../util/isElementNested.js'
import MaterialBomContextMenu from './MaterialBomContextMenu.vue'
import MaterialBomColorCode from './MaterialBomColorCode.vue'

export default {
  components: {
    DropZone,
    MaterialBomContextMenu,
    MaterialBomColorCode
  },
  props: {
    bom: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  inject: ['store'],
  data () {
    return {
      isDropTarget: false
    }
  },
  computed: {
    allowEdit () {
      return this.store.rbac.hasPermission('edit', 'lineItem', {
        sectionName: this.sectionName
      })
    },
    bomLinkParams () {
      const section = this.store.router.params.currentSection
      return {
        path: `/bom/${section}`,
        query: {
          id: this.bom.bompartId
        }
      }
    },
    noMaterial () {
      return (
        !this.lineItem.colorId &&
        !this.lineItem.materialCode &&
        !this.lineItem.supplierCode
      )
    },
    // checks if bom is locked
    isMRPlock () {
      const bompartId = this.bom.bompartId
      const { bomEditor } = this.store
      const checkMRPlock = bomEditor.checkMRPlock(bompartId)
      return checkMRPlock
    },
    isSeasonal () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      if (validLineItems.includes(this.component)) {
        return this.bom.seasonalLookId !== null
      } else {
        return false
      }
    },
    isPowerCore () {
      const bompartId = this.bom.bompartId
      const { bomEditor } = this.store
      const checkPowerCore = bomEditor.checkPowerCore(bompartId)
      return checkPowerCore
    }
  },
  methods: {
    async addMaterial (transferData) {
      const { alertDialog } = this.store
      if (!(this.isBOMlocked(this.bom.bompartId)) && !this.isSeasonal) {
        const overrides = {
          quantity: this.lineItem.quantity,
          lscoRemarks: this.lineItem.lscoRemarks
        }

        const result = await this.store.confirmationDialog.show({
          confirmText: 'Add Material',
          component: AddMaterial,
          props: {
            lineItem: overrides
          },
          title: 'Add Material'
        })

        if (result) {
          this.updateMaterial(transferData, overrides)
        }
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    },
    isValidTarget (element) {
      return isElementNested(element, this.$el)
    },
    onDragleave (transferData = {}, event) {
      const isValidTarget = this.isValidTarget(event.relatedTarget)
      if (!event.relatedTarget || !isValidTarget) {
        this.isDropTarget = false
      }
    },
    onDragover (transferData = {}, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)
      if (isValidTarget && isValidTransferData) {
        this.isDropTarget = true
      }
    },
    onDrop (transferData = {}, event) {
      const isValidTarget = this.isValidTarget(event.target)
      if (isValidTarget && transferData.material && transferData.supplier) {
        if (this.noMaterial) {
          this.addMaterial(transferData)
        } else {
          this.replaceMaterial(transferData)
        }
        this.isDropTarget = false
      }
    },
    async replaceMaterial (transferData) {
      const { alertDialog } = this.store
      const newMaterial = transferData.materialName
      const oldMaterial = this.lineItem.materialDescription
      if (!(this.isBOMlocked(this.bom.bompartId)) && !this.isSeasonal && !(this.isPowerCoreEnabled(this.bom.bompartId))) {
        const result = await this.store.confirmationDialog.show({
          confirmText: 'Replace',
          component: ConfirmReplacement,
          props: {
            newMaterial,
            oldMaterial,
            parent: this.bom.colorwayName
          },
          title: 'Confirm Replacement'
        })
        if (result) {
          this.updateMaterial(transferData)
        }
      } else if (this.isPowerCore) {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom is locked because the PC9 is Power Core.'
          },
          title: 'Error'
        })
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    },
    async updateMaterial (transferData, overrides = {}) {
      const { bomEditor, bomEditorApi, router } = this.store

      const lineItem = {
        materialCode: transferData.material,
        supplierCode: transferData.supplier,
        colorId: transferData.color,
        ...overrides
      }

      const popups = {
        errorText: `There was an error adding ${transferData.materialName}`
      }

      await bomEditorApi.updateLineItem({
        lineItemIds: [this.bom.bomlinkId],
        lineItem,
        popups,
        // These params are only used by the Dev BOM API
        bompartId: this.bom.bompartId,
        section: this.lineItem.section
      })

      router.setHash({
        showLineItem: this.bom.bomlinkId
      })
      bomEditor.refresh()
    },
    isBOMlocked (id) {
      const { bomEditor } = this.store
      return bomEditor.checkMRPlock(id)
    },
    isPowerCoreEnabled (id) {
      const { bomEditor } = this.store
      return bomEditor.checkPowerCore(id)
    }
  }
}
</script>

<style lang="scss">
.MaterialBomItem {
  $borderRadius: 5rem;

  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.3rem;
  border-radius: $borderRadius;
  border: 1px solid grey;
  position: relative;
  background-color: white;
  cursor: pointer;
  min-width: 1px;
  max-width: 100%;
  width: 100%;
  height: 38px;
  overflow: hidden;

  .ColorwayNameColorCodeWrapper {
    display: flex;
    align-items: center;
    overflow: hidden;

    svg {
      margin-right: 8px;
    }
  }

  &:hover {
    border-color: $gray-sec;
    color: $gray;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    overflow: hidden;
    width: 100%;

    @include xs {
      width: 100%;
    }

    @include sm {
      width: 100%;
    }
  }

  .colorwayName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $gray-text;
  }

  .ContextMenu {
    margin-left: 1rem;
  }

  .DropZone.overlay {
    .background {
      border-radius: $borderRadius;
    }

    .foreground {
      font-size: 1.5rem;
    }
  }

  &.dropTarget {
    .dropZone {
      display: flex;
    }
  }
}
</style>
