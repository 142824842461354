<template>
  <drop
    class="BomEditorList"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <DropZone
      class="bomListDropZone"
      :is-showing="isDropTarget"
      mode="listItem"
    >
      Add Subsection
    </DropZone>
    <Scroller v-if="show">
      <BomSubsection
        v-for="bomComponent in selectedComponents"
        :key="`${sectionName}__${bomComponent.subsection}`"
        :bom-component="bomComponent"
        :section-name="sectionName"
      />
    </Scroller>
    <Spinner
      v-else
      class="stretch"
    />
  </drop>
</template>

<script>
import AddSubsection from './dialogs/AddSubsection.vue'
import BomSubsection from './BomSubsection.vue'
import DropZone from '../DropZone.vue'
import isElementNested from '../../util/isElementNested.js'
import Scroller from '../Scroller.vue'
import Spinner from '../Spinner.vue'
import AlertMessage from './dialogs/AlertMessage.vue'
export default {
  components: {
    BomSubsection,
    DropZone,
    Scroller,
    Spinner
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      show: true,
      isDropTarget: false
    }
  },
  computed: {
    selectedComponents () {
      const { bomEditor } = this.store
      const section = bomEditor[this.sectionName]
      const components = section.components
      const selected = components.filter((component) => {
        const some = component.lineItemDetails.some((lineItem) => {
          return lineItem.bompartInfo.some((bomPart) => {
            return bomEditor.filters.includes(bomPart.bompartId)
          })
        })
        return some
      })
      const sortByComponent = bomEditor.sortBySubsection(this.sectionName)
      selected.sort(sortByComponent)
      return selected
    },
    section () {
      return this.store.bomEditor[this.sectionName]
    }
  },
  watch: {
    'store.router.params.currentSection': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 0)
      }
    }
  },
  methods: {
    async addSubsection (transferData) {
      const { bomEditor, bomEditorApi, confirmationDialog, alertDialog, router } = this.store

      const overrides = {
        quantity: bomEditor.getDefaultQuantity(this.sectionName),
        lscoRemarks: null
      }
      // to filter bomIds of BOM which are not locked
      const bomIdsNotLocked = bomEditor.selectedBomIds.filter(bomId => bomEditor.checkMRPlock(bomId) === false)
      if (bomIdsNotLocked.length > 0) {
        const result = await confirmationDialog.show({
          confirmText: 'Add Subsection',
          component: AddSubsection,
          props: {
            lineItem: overrides,
            sectionName: this.sectionName
          },
          title: 'Add Subsection'
        })

        if (result) {
          // console.log(overrides.component, bomIds)
          const component = overrides.component
          let bomIds = []
          if (component === 'A' || component === 'primaryFinish') {
            bomIds = bomEditor.hasPrimaryFabric(bomIdsNotLocked, this.sectionName)
          } else {
            bomIds = bomIdsNotLocked
          }
          if (bomIds.length > 0) {
            const lineItem = {
              materialCode: transferData.material,
              supplierCode: transferData.supplier,
              colorId: transferData.color,
              section: this.sectionName,
              ...overrides
            }
            const popups = {
              errorText: `There was an error adding ${transferData.materialDescription}`
            }
            const response = await bomEditorApi.addLineItem({
              bomIds,
              lineItem,
              popups
            })
            router.setHash({
              showLineItem: response[0].bomlinkId
            })
            bomEditor.refresh()
          } else {
            await alertDialog.show({
              component: AlertMessage,
              props: {
                message: `Action could not be completed. Bom Already have a primary ${this.sectionName}.`
              },
              title: 'Error'
            })
          }
        }
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    },
    isValidTarget (element) {
      return isElementNested(
        element,
        this.$el,
        '.BomSubsection'
      )
    },
    onDragover (transferData = {}, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)
      if (isValidTarget && isValidTransferData) {
        this.isDropTarget = true
      }
    },
    onDragleave (transferData = {}, event) {
      const isValidTarget = this.isValidTarget(event.relatedTarget)
      if (!isValidTarget) {
        this.isDropTarget = false
      }
    },
    onDrop (transferData = {}, event) {
      const isValidTarget = this.isValidTarget(event.target)
      const isValidTransferData = Object.keys(transferData).length
      this.isDropTarget = false
      if (isValidTarget && isValidTransferData) {
        this.addSubsection(transferData)
      }
    }
  }
}
</script>

<style lang="scss">
.BomEditorList {
  $scrollerPaddingRight: 4rem;
  $padding: 2rem;

  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  padding-top: $padding;

  // The list needs this padding to help with the list's DropZone
  padding-left: $scrollerPaddingRight;

  .DropZone.bomListDropZone {
    margin-bottom: $padding;
    // Correct the width to line up with the subsections in the Scoller
    width: calc(100% - #{$scrollerPaddingRight});
  }

  .Scroller {
    padding-bottom: $padding;
    padding-right: $scrollerPaddingRight;
    overflow-y: scroll;
    height: calc(100% - #{$padding});
  }
}
</style>
