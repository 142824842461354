<template>
  <div class="BomSearchComponentLocationField">
    <label>Component Location</label>
    <select
      v-model="store.bomSearch.compLocation"
    >
      <option
        :selected="null"
        :value="null"
      />
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  inject: ['store'],
  computed: {
    options () {
      const componentLocations = this.store.codes.componentLocations
      return componentLocations.sort((a, b) => {
        return a.label.localeCompare(b.label, 'en', { numeric: true })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.BomSearchComponentLocationField {
  select {
    text-transform: capitalize;
  }
}
</style>
