<template>
  <div class="AssignBomModals">
    <Modal
      name="assign-bom-choose-enter-or-lookup"
      title="Assign Development BOM to PC9s"
    >
      <ChooseEnterOrLookup />
    </Modal>

    <Modal
      name="assign-bom-enter-pc9s"
      title="Enter PC9s"
    >
      <EnterPC9Codes />
    </Modal>

    <Modal
      name="assign-bom-look-up-pc9s-season"
      title=" Look Up PC9s"
    >
      <LookUpPC9CodesSeason />
    </Modal>

    <Modal
      name="assign-bom-look-up-pc9s-pc5"
      title=" Look Up PC9s"
    >
      <LookUpPC9CodesPC5 />
    </Modal>

    <Modal
      name="assign-bom-look-up-pc9s-attributes"
      title=" Look Up PC9s"
    >
      <LookUpPC9CodesAttributes />
    </Modal>

    <Modal
      name="assign-bom-pc9-results"
      title="Results for..."
    >
      <SearchResults />
    </Modal>

    <Modal
      name="assign-bom-choose-sections"
      title="Sections to Apply"
    >
      <ChooseSections />
    </Modal>

    <Modal
      name="assign-bom-review"
      title="Review Your Selections"
    >
      <ReviewSelections />
    </Modal>

    <Modal
      name="assign-bom-errors"
      title="Error Assigning PC9s"
    >
      <AssignErrors />
    </Modal>
  </div>
</template>

<script>
import AssignErrors from './AssignErrors.vue'
import ChooseEnterOrLookup from './ChooseEnterOrLookup.vue'
import ChooseSections from './ChooseSections.vue'
import EnterPC9Codes from './EnterPC9Codes.vue'
import LookUpPC9CodesAttributes from './LookUpPC9CodesAttributes.vue'
import LookUpPC9CodesPC5 from './LookUpPC9CodesPC5.vue'
import LookUpPC9CodesSeason from './LookUpPC9CodesSeason.vue'
import Modal from '../Modal.vue'
import SearchResults from './SearchResults.vue'
import ReviewSelections from './ReviewSelections.vue'

export default {
  components: {
    AssignErrors,
    ChooseEnterOrLookup,
    ChooseSections,
    EnterPC9Codes,
    LookUpPC9CodesAttributes,
    LookUpPC9CodesPC5,
    LookUpPC9CodesSeason,
    Modal,
    SearchResults,
    ReviewSelections
  }
}
</script>

<style lang="scss">
  .ModalButtonsWrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;

    .SelectAllLink {
      margin-right: 24px;
    }
  }
</style>
