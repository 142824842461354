<template>
  <SubHeader class="DevBomEditHeader">
    <template v-slot:leftSide>
      <h3>
        <DevBomTitleEditor :item="bom" />
      </h3>
      <DevBomEditHeaderMenu />
      <DevBomCopiedFrom />
    </template>

    <template v-slot:rightSide>
      <ModalLink
        name="review-mode"
        class="button"
      >
        Review Mode
      </ModalLink>

      <Modal
        name="review-mode"
        title="Review Mode"
      >
        <BomReview />
      </Modal>
    </template>
  </SubHeader>
</template>

<script>
import BomReview from '../BomReview/BomReview.vue'
import DevBomCopiedFrom from './DevBomCopiedFrom.vue'
import DevBomEditHeaderMenu from './DevBomEditHeaderMenu.vue'
import DevBomTitleEditor from './DevBomTitleEditor.vue'
import Modal from '../Modal.vue'
import ModalLink from '../ModalLink.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    BomReview,
    DevBomCopiedFrom,
    DevBomEditHeaderMenu,
    DevBomTitleEditor,
    Modal,
    ModalLink,
    SubHeader
  },
  inject: [ 'store' ],
  computed: {
    bom () {
      return this.store.bomEditor.results.BOMResults[0] || {}
    }
  }
}
</script>
