<template>
  <div class="Pagination">
    <a
      class="previous"
      :class="{ active: previousPageLink.isActive }"
      @click="onPageClick(previousPageLink.value)"
    >
      <ChevronLeftIcon />
      <span>Previous</span>
    </a>

    <!-- Page Numbers -->

    <ul
      v-if="showPageNumbers"
      class="pageNumbers"
    >
      <li
        v-for="item in pageLinks"
        :key="item.key"
      >
        <a
          v-if="isPage(item)"
          :class="{ active: item.isActive}"
          @click="onPageClick(item.value)"
        >
          {{ item.value }}
        </a>
        <span v-if="isEllipsis(item)">
          …
        </span>
      </li>
    </ul>

    <!-- Find Materials Search Results -->

    <div v-if="type === 'materials'">
      <span
        v-if="itemCount > materialsPerPage"
        class="MaterialResults"
      >{{ materialsStart }}-{{ materialsEnd }} of {{ itemCount }} materials found
      </span>
      <span
        v-if="itemCount <= materialsPerPage"
        class="MaterialResults"
      >{{ itemCount }} {{ itemCount === 1 ? 'material' : 'materials' }} found</span>
    </div>

    <a
      class="next"
      :class="{ active: nextPageLink.isActive }"
      @click="onPageClick(nextPageLink.value)"
    >
      <span>Next</span>
      <ChevronRightIcon />
    </a>
  </div>
</template>

<script>
import pagination from 'ultimate-pagination'
import ChevronLeftIcon from './icons/ChevronLeftIcon.vue'
import ChevronRightIcon from './icons/ChevronRightIcon.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  inject: [ 'store' ],
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    showPageNumbers: {
      type: Boolean,
      default: true
    },
    itemCount: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    nextPageLink () {
      return this.paginationModel.find((item) => {
        return this.isNextPage(item)
      })
    },
    pageLinks () {
      return this.paginationModel.filter((item) => {
        return (
          this.isPage(item) ||
          this.isEllipsis(item)
        )
      })
    },
    paginationModel () {
      const currentPage = Math.min(this.currentPage, this.totalPages)
      return pagination.getPaginationModel({
        currentPage,
        siblingPagesRange: 3,
        totalPages: this.totalPages
      })
    },
    previousPageLink () {
      return this.paginationModel.find((item) => {
        return this.isPreviousPage(item)
      })
    },
    totalPages () {
      return Math.ceil(this.itemCount / this.itemsPerPage) || 1
    },
    materialsPerPage () {
      return this.store.findMaterial.perPage
    },
    materialsStart () {
      return this.store.findMaterial.start
    },
    materialsEnd () {
      return this.store.findMaterial.end
    }

  },
  methods: {
    isEllipsis (item) {
      return item.type === pagination.ITEM_TYPES.ELLIPSIS
    },
    isNextPage (item) {
      return item.type === pagination.ITEM_TYPES.NEXT_PAGE_LINK
    },
    isPage (item) {
      return item.type === pagination.ITEM_TYPES.PAGE
    },
    isPreviousPage (item) {
      return item.type === pagination.ITEM_TYPES.PREVIOUS_PAGE_LINK
    },
    onPageClick (value) {
      this.model[this.name] = value
    }
  }
}
</script>

<style lang="scss">
.Pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .previous {
    svg {
      margin-right: 0.8rem;
    }
  }

  .next {
    svg {
      margin-left: 0.8rem;
    }
  }

  .previous,
  .next {
    display: flex;
    align-items: center;
    color: $blue;
    padding: 0.2rem 0.5rem;
    transition: $anim-trans-base;

    svg path {
      fill: $blue;
    }

    &.active {
      color: $gray-tertiary;
      cursor: default;

      svg path {
        fill: $gray-tertiary;
      }
    }
  }

  ul {
    display: flex;

    li {
      a {
        padding: 0.6rem 1rem;
        display: inline-block;
        color: $gray-sec;
        border-radius: 4px;
        transition: $anim-trans-base;

        &:hover {
          color: $blue;
        }

        &.active {
          color: $gray;
        }
      }
    }
  }
}
</style>
