<template>
  <div class="BackLink">
    <ChevronLeftIcon />
    <span>Back</span>
  </div>
</template>

<script>
import ChevronLeftIcon from './icons/ChevronLeftIcon.vue'
export default {
  components: {
    ChevronLeftIcon
  }
}
</script>

<style lang="scss">
.BackLink {
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;

    path {
      fill: $blue;
    }
  }
}
</style>
