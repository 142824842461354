<template>
  <div class="DevBomTitleEditor">
    <div v-if="isEditing">
      <input
        ref="input"
        v-model="value"
        @blur="onInputBlur"
        @keydown="onInputKeydown"
      >
      <span v-if="store.devBomRename.isError">
        <BangCircleIcon />
      </span>
    </div>
    <div v-else>
      <span
        class="staticText"
        @click="onTitleClick"
      >{{ title }}</span>
    </div>
  </div>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'

export default {
  components: {
    BangCircleIcon
  },
  inject: ['store'],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    isEditing () {
      return (
        this.store.devBomRename.isEditing &&
        this.item.bompartId === this.store.devBomRename.bomId
      )
    },
    title () {
      return this.item.title || 'Untitled'
    }
  },
  watch: {
    isEditing (newValue, oldValue) {
      if (newValue) {
        this.setFocus()
      }
    }
  },
  mounted () {
    if (this.isEditing) {
      this.setFocus()
    }
  },
  methods: {
    onInputBlur () {
      if (this.store.devBomRename.isEditing && this.isEditing) {
        this.save()
      }
    },
    onInputKeydown (event) {
      if (event.key === 'Enter') {
        this.save()
      } else if (event.key === 'Escape') {
        this.store.devBomRename.cancel()
      }
    },
    onTitleClick () {
      const bomId = this.item.bompartId
      this.store.devBomRename.edit(bomId)
    },
    async save () {
      const bomId = this.item.bompartId
      const title = this.value
      const response = await this.store.devBomRename.save({ bomId, title })
      this.item.title = response.title
    },
    setFocus () {
      this.value = this.item.title
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss">
.DevBomTitleEditor {
  .staticText {
    cursor: pointer;
  }
}
</style>
