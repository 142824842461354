<template>
  <drop
    class="BomSubsection"
    :class="{ selected: isShowComponent }"
    :data-component="component || ''"
    :data-componentLocation="componentLocation || ''"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <Collapsable
      :model="store.bomEditor.toggles"
      :model-key="toggleKey"
    >
      <template v-slot:title>
        <div class="subsectionHeader">
          <SubsectionTitle
            :component="bomComponent.component"
            :component-location="bomComponent.componentLocation"
          />
          <SubsectionContextMenu
            :bom-component="bomComponent"
            :section-name="sectionName"
            :subsection-title="title"
            :component="component"
          />
        </div>
      </template>

      <template v-slot:default>
        <LineItem
          v-for="lineItem in selectedLineItems"
          :key="buildLineItemKey(lineItem)"
          :line-item="lineItem"
          :subsection-title="title"
          :section-name="sectionName"
          :component="component"
        />
        <DropZone
          :is-showing="isDropTarget"
          mode="listItem"
        >
          Add New Item
        </DropZone>
      </template>
    </Collapsable>
  </drop>
</template>

<script>
import AddMaterial from './dialogs/AddMaterial.vue'
import AlertMessage from './dialogs/AlertMessage.vue'
import Collapsable from '../Collapsable.vue'
import compareLineItems from '../../util/compareLineItems.js'
import DropZone from '../DropZone.vue'
import isElementNested from '../../util/isElementNested.js'
import LineItem from './LineItem.vue'
import SubsectionContextMenu from './SubsectionContextMenu.vue'
import SubsectionTitle from '../SubsectionTitle.vue'

export default {
  components: {
    LineItem,
    Collapsable,
    DropZone,
    SubsectionContextMenu,
    SubsectionTitle
  },
  inject: [ 'store' ],
  props: {
    bomComponent: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDropTarget: false
    }
  },
  computed: {
    component () {
      return this.bomComponent.component
    },
    componentLabel () {
      return this.store.components.formatComponent(this.component)
    },
    componentLocationLabel () {
      return this.store.components.formatComponentLocation(this.componentLocation)
    },
    componentLocation () {
      return this.bomComponent.componentLocation
    },
    isShowComponent () {
      if (this.showComponent.length) {
        const parts = this.showComponent.split('_')
        const [ component = '', componentLocation = '' ] = parts
        const thisComponent = this.bomComponent.component || ''
        const thisLocation = this.bomComponent.componentLocation || ''
        return (
          thisComponent === component &&
          thisLocation === componentLocation
        )
      }
      return false
    },
    selectedLineItems () {
      const { bomEditor } = this.store
      const lineItems = this.bomComponent.lineItemDetails.filter((lineItem) => {
        return lineItem.bompartInfo.some((bomPart) => {
          return bomEditor.filters.includes(bomPart.bompartId)
        })
      })
      const sorted = [ ...lineItems ].sort(compareLineItems)
      return sorted
    },
    // nonSeasonalLooks () {
    //   const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
    //   if (validLineItems.includes(this.component)) {
    //     return this.bomComponent.lineItemDetails.filter(item => item.bompartInfo.filter(bom => bom.seasonalLookId === null).length > 0 ? item : null)
    //   } else {
    //     return this.bomComponent.lineItemDetails
    //   }
    // },
    showComponent () {
      return this.store.router.hash.showComponent || ''
    },
    title () {
      return `${this.componentLabel} - ${this.componentLocationLabel}`
    },
    toggleKey () {
      const { bomEditor } = this.store
      return bomEditor.toggleKey(this.sectionName, this.bomComponent)
    }
  },
  mounted () {
    if (this.isShowComponent) {
      this.$el.scrollIntoView()
    }
  },
  methods: {
    async addLineItem (transferData) {
      const { bomEditor, bomEditorApi, confirmationDialog, alertDialog, router } = this.store
      // only add lineItems if BOM's are unlocked
      const selectedUnlockedBomIds = bomEditor.selectedBomIds.filter(id => bomEditor.checkMRPlock(id) ? null : id)
      if (selectedUnlockedBomIds.length > 0) {
        const overrides = {
          quantity: bomEditor.getDefaultQuantity(this.sectionName),
          lscoRemarks: null
        }

        const result = await confirmationDialog.show({
          confirmText: 'Add Material',
          component: AddMaterial,
          props: {
            lineItem: overrides
          },
          title: 'Add Material'
        })

        if (result) {
          // console.log(this.sectionName)
          let bomIds = []
          if (this.component === 'A' || this.component === 'primaryFinish') {
            bomIds = bomEditor.hasPrimaryFabric(selectedUnlockedBomIds, this.sectionName)
          } else {
            bomIds = selectedUnlockedBomIds
          }
          if (bomIds.length > 0) {
            const popups = {
              errorText: `There was an error adding ${transferData.materialName}`
            }

            const lineItem = {
              section: this.sectionName,
              component: this.bomComponent.component,
              componentLocation: this.bomComponent.componentLocation,
              colorId: transferData.color,
              materialCode: transferData.material,
              supplierCode: transferData.supplier,
              quantity: overrides.quantity,
              lscoRemarks: overrides.lscoRemarks
            }
            const response = await bomEditorApi.addLineItem({
              bomIds: selectedUnlockedBomIds,
              lineItem,
              popups
            })
            router.setHash({
              showLineItem: response[0].bomlinkId
            })
            bomEditor.refresh()
          } else {
            await alertDialog.show({
              component: AlertMessage,
              props: {
                message: `Action could not be completed. Bom Already have a primary ${this.sectionName}.`
              },
              title: 'Error'
            })
          }
        }
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          }
        })
      }
    },
    buildLineItemKey (lineItem) {
      return `${lineItem.materialCode}_${lineItem.supplierCode}_${lineItem.colorId}_${lineItem.quantity}_${lineItem.lscoRemarks}`
    },
    isValidTarget (element) {
      return isElementNested(
        element,
        this.$el,
        '.LineItem'
      )
    },
    onDragover (transferData = {}, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)
      if (
        isValidTransferData &&
        isValidTarget &&
        transferData.material &&
        transferData.supplier
      ) {
        this.isDropTarget = true
      }
    },
    onDragleave (transferData, event) {
      const isValidTarget = this.isValidTarget(event.relatedTarget)
      if (!isValidTarget) {
        this.isDropTarget = false
      }
    },
    onDrop (transferData = {}, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)
      if (
        isValidTransferData &&
        isValidTarget &&
        transferData.material &&
        transferData.supplier
      ) {
        this.isDropTarget = false
        this.addLineItem(transferData)
      }
    }
  }
}
</script>

<style lang="scss">
.BomSubsection {
  margin: 1.2rem 0;
  padding: 0 2rem;
  padding-bottom: 0.4rem;
  background: $gray-component-background;
  transition: border 0.5s ease, box-shadow 0.5s ease, padding 100ms ease;
  box-sizing: border-box;

  // Prevent size changes for selected animations
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    border: 1px solid fade-out($gray-sec, 0.9);
    box-shadow: 0 4px 4px fade-out($gray-sec, 0.9);
  }

  &:first-child {
    margin-top: 0;
  }

  &.selected {
    animation: SelectAndFade 2s none;
  }

  .collapsableTitle {
    width: 100%;

    .subsectionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 2rem;
    }
  }

  .newItemDropZone {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    background-color: lighten($green, 25%);
    opacity: 0.8;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    transition: height 100ms ease;
    overflow: hidden;
  }

  &.dropTarget {
    padding-left: 4rem;
    padding-right: 4rem;

    .DropZone {
      height: 6rem;
      border: 3px dashed $green;
    }
  }
}
</style>
