<template>
  <div class="DevBoms page">
    <p
      v-if="viewOnly"
      class="error"
    >
      Sorry, you do not have access to view this page.
    </p>

    <DevBomsHeader v-if="!viewOnly" />
    <DevBomList v-if="!viewOnly" />
  </div>
</template>

<script>
import DevBomsHeader from '../components/DevBomLibrary/DevBomsHeader.vue'
import DevBomList from '../components/DevBomLibrary/DevBomList.vue'

export default {
  components: {
    DevBomsHeader,
    DevBomList
  },
  inject: ['store'],
  computed: {
    viewOnly () {
      return this.store.rbac.hasPermission('view', 'devBoms')
    }
  }
}
</script>

<style lang="scss">
.DevBoms.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .error {
    text-align: center;
  }
}
</style>
