<template>
  <li
    class="SearchCriteriaItem"
    @click="onClick"
  >
    <XIcon class="icon" />
    <span>{{ item.label }}</span>
  </li>
</template>

<script>
import XIcon from '../icons/XIcon.vue'

export default {
  components: {
    XIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick () {
      this.$emit('removeItem', this.item)
    }
  }
}
</script>

<style lang="scss">
.SearchCriteriaItem {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: $gray-sec;
  cursor: pointer;

  .icon {
    margin-right: 0.5rem;
    width: 12px;
    height: 12px;

    path {
      stroke: $gray-tertiary;
    }
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    color: $red;

    .icon {
      path {
        stroke: $red;
      }
    }
  }
}
</style>
