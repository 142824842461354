<template>
  <div class="AddSubsection">
    <div class="subsectionFields">
      <div>
        <label>Component</label>
        <ComponentDropdown
          v-model="selectedValue"
          :section-name="sectionName"
          :model="lineItem"
          name="component"
        />
      </div>

      <div>
        <label>Component Location</label>
        <ComponentLocationDropdown
          :section-name="sectionName"
          :model="lineItem"
          name="componentLocation"
        />
      </div>
    </div>
    <div
      v-if="showError"
      class="error"
    >
      Component can't be updated to primary {{ sectionName }}
    </div>
  </div>
</template>

<script>
import ComponentDropdown from '../ComponentDropdown.vue'
import ComponentLocationDropdown from '../ComponentLocationDropdown.vue'

export default {
  components: {
    ComponentDropdown,
    ComponentLocationDropdown
  },
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showError: false,
      selectedValue: null,
      componentName: this.lineItem.component
    }
  },

  methods: {
    validate () {
      if ((this.selectedValue === 'A' && this.componentName !== 'A') || (this.selectedValue === 'primaryFinish' && this.componentName !== 'primaryFinish')) {
        this.showError = true
        return false
      } else {
        this.showError = false
        return true
      }
    }
  }
}
</script>

<style lang="scss">
.AddSubsection {
  padding: 1rem 4rem;

  .subsectionFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  .error {
    color: $red;
  }
}
</style>
