<template>
  <div class="BomSearchSubclassField">
    <label>Subclass</label>
    <select v-model="value">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  computed: {
    options () {
      return this.store.bomSearch.subclassOptions
    },
    value: {
      get () {
        return this.store.bomSearch.subclassPH
      },
      set (value) {
        this.store.bomSearch.subclassPH = value
      }
    }
  }
}
</script>
