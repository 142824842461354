<template>
  <div class="SearchResults page">
    <SearchResultsHeader />
    <BomEditor mode="pc9Search" />
  </div>
</template>

<script>
import BomEditor from '../components/BomEditor/BomEditor.vue'
import SearchResultsHeader from '../components/BomEditor/SearchResultsHeader.vue'

export default {
  components: {
    BomEditor,
    SearchResultsHeader
  }
}
</script>

<style lang="scss">
.SearchResults.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .error {
    h4 {
      color: $red;
    }
  }
}
</style>
