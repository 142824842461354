<template>
  <a
    class="DevBomsShowToggle bom-result-number"
  >
    <ContextMenu
      :y-offset="14"
      :arrow-offset="32"
    >
      <template v-slot:button>
        <span v-if="isShowAll">Show All</span>
        <span v-if="isShowFavorites">Show Favorites</span>
        <ChevronDownIcon class="buttonIcon" />
      </template>

      <ul
        class="context-menu show-all"
        :class="{ show: isOpen }"
      >
        <li>
          <a @click="onShowAllClick">
            <span class="icon">
              <Checkmark v-if="isShowAll" />
            </span>
            <span class="label">Show All</span>
          </a>
        </li>
        <li>

          <a @click="onShowFavoritesClick">
            <span class="icon">
              <Checkmark v-if="isShowFavorites" />
            </span>
            <span class="label">Show Favorites</span>
          </a>
        </li>
      </ul>
    </ContextMenu>
  </a>
</template>

<script>
import Checkmark from '../icons/Checkmark.vue'
import ChevronDownIcon from '../icons/ChevronDownIcon.vue'
import ContextMenu from '../ContextMenu.vue'

export default {
  components: {
    Checkmark,
    ChevronDownIcon,
    ContextMenu
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    isShowAll () {
      return this.store.devBoms.showing === 'all'
    },
    isShowFavorites () {
      return this.store.devBoms.showing === 'favorites'
    }
  },
  inject: [ 'store' ],
  methods: {
    onMenuClick () {
      this.isOpen = !this.isOpen
    },
    onShowAllClick () {
      this.store.devBoms.showing = 'all'
    },
    onShowFavoritesClick () {
      this.store.devBoms.showing = 'favorites'
    }
  }
}
</script>

<style lang="scss">
.DevBomsShowToggle {
  .buttonWrapper {
    color: $white;

    .buttonIcon {
      margin-left: 8px;
    }
  }
}

.context-menu-portal-target {
  li a {
    color: $gray;
    width: 100%;

    .icon {
      display: inline-block;
      min-width: 24px;
    }

    &:hover {
      background: rgba(16, 147, 220, 0.15);
    }
  }
}
</style>
