<template>
  <div
    class="CollapsableButton"
    :class="buttonClass"
    @click="onClick"
  >
    <ChevronDownIcon />
  </div>
</template>

<script>
import ChevronDownIcon from './icons/ChevronDownIcon.vue'

export default {
  components: {
    ChevronDownIcon
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    modelKey: {
      type: String,
      required: true
    }
  },
  computed: {
    buttonClass () {
      if (this.isVisible) {
        return 'collapsableButtonVisible'
      }
      return 'collapsableButtonHidden'
    },
    isVisible () {
      return this.model.includes(this.modelKey)
    }
  },
  methods: {
    onClick () {
      if (this.isVisible) {
        const index = this.model.indexOf(this.modelKey)
        this.model.splice(index, 1)
      } else {
        this.model.push(this.modelKey)
      }
    }
  }
}
</script>

<style lang="scss">
.CollapsableButton {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.35s;

  &.collapsableButtonVisible {
    transform: rotateZ(-180deg);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  svg {
    width: 60%;
    height: 60%;
  }
}
</style>
