<template>
  <ContextMenu
    class="MaterialBomContextMenu"
    :x-offset="-130"
    :arrow-offset="139"
  >
    <template v-slot:default>
      <ul>
        <li>
          <router-link :to="editBomLink">
            {{ editBomText }}
          </router-link>
        </li>
        <li v-if="hasMaterial">
          <a @click="onRemoveMaterialClick">
            Remove Material
          </a>
        </li>
      </ul>
    </template>
  </ContextMenu>
</template>

<script>
import ConfirmRemoval from './dialogs/ConfirmRemoval.vue'
import ContextMenu from '../ContextMenu.vue'

export default {
  components: {
    ContextMenu
  },
  inject: [ 'store' ],
  props: {
    bom: {
      type: Object,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    editBomLink () {
      const query = {
        id: this.bom.bompartId
      }
      const path = `/bom`
      return {
        path,
        query
      }
    },
    editBomText () {
      return `View ${this.pc9Code} BOM`
    },
    hasMaterial () {
      return !!this.lineItem.materialCode
    },
    isSingleBom () {
      return this.store.bomEditor.count === 1
    },
    pc9Code () {
      return this.bom.colorwayCode
    },
    replaceParams () {
      return {
        bomlinkId: this.bom.bomlinkId
      }
    }
  },
  methods: {
    async onRemoveMaterialClick () {
      const { materialDescription } = this.lineItem
      const result = await this.store.confirmationDialog.show({
        confirmClass: 'remove',
        confirmText: 'Remove',
        component: ConfirmRemoval,
        props: {
          target: materialDescription,
          parent: this.bom.colorwayName
        },
        title: 'Confirm Removal'
      })
      if (result) {
        const { bomEditor, bomEditorApi, router } = this.store
        await bomEditorApi.removeMaterial({
          lineItemIds: [ this.bom.bomlinkId ],
          materialDescription
        })
        router.setHash({
          showLineItem: this.bom.bomlinkId
        })
        bomEditor.refresh()
      }
    }
  }
}
</script>
