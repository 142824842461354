<template>
  <form
    class="LookUpPC9CodesPC5"
    @submit.prevent="onSubmit"
  >
    <section class="seasonSection">
      <div class="gutter">
        <div class="stepNumber">
          1
        </div>
      </div>
      <div class="seasonName">
        <h4>{{ seasonName }}</h4>
      </div>
    </section>
    <section>
      <div class="gutter">
        <div class="stepNumber">
          2
        </div>
      </div>
      <div class="limitToPC5">
        <h4>Limit to specific PC5?</h4>
        <div class="inputs">
          <RadioGroup
            :model="assign.lookup"
            name="limitToPC5"
            :options="limitOptions"
          />
          <input
            v-if="assign.lookup.limitToPC5"
            v-model="assign.lookup.PC5CodeText"
            class="PC5CodeText"
            type="text"
            placeholder="ex. 00501"
          >
        </div>
      </div>
    </section>

    <div
      v-if="assign.lookup.showErrors"
      class="error"
    >
      Please enter a PC5 code
    </div>

    <div class="footer">
      <ModalLink
        class="backLink"
        name="assign-bom-look-up-pc9s-season"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div>
        <button
          class="button"
          type="submit"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import BackLink from '../BackLink.vue'
import ModalLink from '../ModalLink.vue'
import RadioGroup from '../RadioGroup.vue'

export default {
  inject: [ 'store' ],
  components: {
    BackLink,
    ModalLink,
    RadioGroup
  },
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        PC5Code: assign.lookup.PC5Code,
        seasonId: modals.params.seasonId
      }
    },
    limitOptions () {
      return [{
        value: true,
        label: 'Yes'
      }, {
        value: false,
        label: 'No'
      }]
    },
    nextParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        PC5Code: assign.lookup.PC5Code
      }
    },
    pc5: {
      get () {
        return this.assign.lookup.limitToPC5Text
      },
      set (value) {
        this.assign.lookup.limitToPC5Text = value
      }
    },
    seasonName () {
      const { modals, seasons } = this.store
      const season = seasons.find(modals.params.seasonId)
      return season.seasonName
    }
  },
  mounted () {
    const { assign, modals } = this.store
    assign.lookup.showErrors = false
    assign.lookup.PC5Code = modals.params.PC5Code
  },
  methods: {
    onSubmit () {
      const { assign, modals } = this.store
      assign.lookup.showErrors = false
      if (assign.lookup.isPC5Valid) {
        modals.show('assign-bom-look-up-pc9s-attributes', this.nextParams)
      } else {
        assign.lookup.showErrors = true
      }
    }
  }
}
</script>

<style lang="scss">
.LookUpPC9CodesPC5 {
  @include assignFindPC9Codes();

  min-width: 400px;

  section.seasonSection {
    align-items: center;
  }

  .limitToPC5 {
    padding-top: 1rem;

    .inputs {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 2rem;

      $inputHeight: 38px;

      .RadioGroup label {
        height: $inputHeight;
      }

      .PC5CodeText {
        height: $inputHeight;
        margin-left: 2rem;
      }
    }
  }
}
</style>
