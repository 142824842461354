<template>
  <div class="PreviewLineItem">
    <div v-if="hasMaterial">
      <div class="row">
        <div class="col">
          {{ lineItem.materialDescription }}
        </div>
        <div class="col">
          {{ lineItem.supplierDescription }}
        </div>
      </div>

      <div class="row">
        <div
          class="col"
          :class="{ noColor: !hasColor }"
        >
          {{ colorDescription }}
        </div>
        <div class="col">
          <span>Quantity: </span>
          <span>{{ lineItem.quantity }}</span>
        </div>
      </div>

      <div class="cell remarks">
        <span>Remarks: </span>
        <span>{{ lineItem.lscoRemarks }}</span>
      </div>
    </div>
    <div
      v-else
      class="noMaterial"
    >
      <BangCircleIcon />
      <p>No material added</p>
    </div>
  </div>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'

export default {
  components: {
    BangCircleIcon
  },
  inject: [ 'store' ],
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    showCard: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    colorDescription () {
      if (this.hasColor) {
        return this.lineItem.colorDescription
      }
      return 'No color'
    },
    hasColor () {
      return (
        this.lineItem.colorDescription &&
        this.lineItem.colorDescription.length
      )
    },
    hasMaterial () {
      return (
        this.lineItem.materialDescription ||
        this.lineItem.supplierDescription ||
        this.lineItem.colorDescription
      )
    }
  }
}
</script>

<style lang="scss">
.PreviewLineItem {
  @include card;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .col {
      flex-basis: 50%;
    }
  }

  .noColor {
    color: $gray-sec;
  }

  .noMaterial {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}
</style>
