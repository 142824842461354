<template>
  <div
    class="Sidebar"
    :class="sidebarClass"
  >
    <ul class="sectionList">
      <BomSidebarSection
        section-name="fabric"
      />

      <BomSidebarSection
        section-name="sundries"
      />

      <BomSidebarSection
        section-name="thread"
      />

      <BomSidebarSection
        section-name="finish"
      />

      <BomSidebarSection
        section-name="embellishment"
      />

      <BomSidebarSection
        section-name="careLabel"
      />

      <BomSidebarSection
        section-name="other"
      />

      <BomSidebarSection
        section-name="artwork"
      />

      <BomSidebarSection
        section-name="pkgAndLabels"
      />
    </ul>
    <FindMaterialSlider :section-name="sectionName" />
  </div>
</template>

<script>
import BomSidebarSection from './BomSidebarSection.vue'
import FindMaterialSlider from '../FindMaterial/FindMaterialSlider.vue'

export default {
  components: {
    BomSidebarSection,
    FindMaterialSlider
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      default: 'Material'
    }
  },
  computed: {
    sidebarClass () {
      if (this.store.findMaterial.isOpen) {
        return 'wide'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.Sidebar {
  width: $sidebarWidth;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  transition: width 100ms ease;

  &.wide {
    width: 480px;
  }

  ul.sectionList {
    height: 100%;
    border-right: 1px solid $gray-lines;
  }
}
</style>
