<template>
  <div
    v-if="sections.length"
    class="CreateDevBomPreview"
  >
    <PreviewSection
      v-for="section in sections"
      :key="section.sectionName"
      :section="section"
    />
  </div>
  <div v-else>
    <h4>
      No sections selected.
    </h4>
  </div>
</template>

<script>
import PreviewSection from './PreviewSection.vue'

export default {
  components: {
    PreviewSection
  },
  inject: [ 'store' ],
  computed: {
    isDev () {
      return this.store.modals.params.createType === 'copyDev'
    },
    isPC9 () {
      return this.store.modals.params.createType === 'copyPC9'
    },
    sections () {
      return this.sectionsToCopy.map((sectionName) => {
        const { copyFromDevBom, copyFromPC9 } = this.store
        if (this.isPC9) {
          const bomId = this.store.modals.params.preview
          if (copyFromPC9.hasBom(bomId)) {
            return {
              sectionName: sectionName,
              section: copyFromPC9.foundBom.sections[sectionName]
            }
          } else {
            copyFromPC9.find(bomId)
          }
        } else if (this.isDev) {
          const bomId = this.store.modals.params.preview
          copyFromDevBom.bomId = bomId
          return {
            sectionName,
            section: copyFromDevBom.bom.sections[sectionName]
          }
        }
        return {}
      })
    },
    sectionsToCopy () {
      // Starting with the keys ensures that the sections are in the
      // desired order, even if they were selected in a different order.
      return this.store.codes.sectionKeys.filter((section) => {
        return this.store.createDevBomPreview.sectionsToCopy.includes(section)
      })
    }
  }
}
</script>

<style lang="scss">
.CreateDevBomPreview {
  width: 100%;
  padding: 2rem;
  overflow-y: auto;
}
</style>
