<template>
  <ContextMenu
    class="DevBomContextMenu"
    :x-offset="-130"
    :arrow-offset="139"
  >
    <ul>
      <li>
        <router-link
          :to="targetUrl"
        >
          View
        </router-link>
      </li>
      <li>
        <ModalLink
          name="share-devbom"
          :params="modalParams"
        >
          Share
        </ModalLink>
      </li>
      <li v-if="!item.isFavorite">
        <a @click="onFavoriteClick">Favorite</a>
      </li>
      <li v-else>
        <a @click="onFavoriteClick">Remove Favorite</a>
      </li>
      <li>
        <a @click="onRenameClick">Rename</a>
      </li>
      <li>
        <a @click="onEditNoteClick">Edit Note</a>
      </li>
      <li>
        <a @click="onCreateCopyClick">Create Copy</a>
      </li>
      <li class="danger newSection">
        <ModalLink
          name="delete-devbom"
          :params="modalParams"
        >
          Delete
        </ModalLink>
      </li>
    </ul>
  </ContextMenu>
</template>

<script>
import ContextMenu from '../ContextMenu.vue'
import ModalLink from '../ModalLink.vue'

export default {
  components: {
    ContextMenu,
    ModalLink
  },
  inject: [ 'store' ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    modalParams () {
      return {
        boms: [ this.item ]
      }
    },
    targetUrl () {
      return `/dev-bom/fabric?id=${this.item.bompartId}`
    }
  },
  methods: {
    onCreateCopyClick () {
      this.store.modals.show('create-dev-bom', {
        createType: 'copyDev',
        preview: this.item.bompartId,
        allowBack: false
      })
    },
    async onFavoriteClick () {
      this.item.isFavorite = !this.item.isFavorite
      const bomId = this.item.bompartId
      try {
        await this.store.devBomApi.toggleFavoriteBom({
          bomId,
          isFavorite: this.item.isFavorite
        })
      } catch (error) {
        this.item.isFavorite = !this.item.isFavorite
        const errorMessage = this.item.isFavorite
          ? 'There was an error removing this BOM from your favorites.'
          : 'There was an error adding this BOM to your favorites.'
        alert(errorMessage)
      }
    },
    onRenameClick () {
      this.store.devBomRename.edit(this.item.bompartId)
    },
    onEditNoteClick () {
      this.store.devBoms.setNoteEdit(this.item.bompartId, true)
    }
  }
}
</script>
