<template>
  <div class="DevBomList">
    <div class="card">
      <div class="dataTable">
        <div
          v-if="isReady && !isEmpty"
          class="th"
        >
          <ListColumnHeader class="check">
            <input
              v-model="selectAll"
              type="checkbox"
            >
          </ListColumnHeader>

          <ListColumnHeader
            class="title"
            :sort-model="store.devBoms"
            sort-name="title"
          >
            Title
          </ListColumnHeader>
          <ListColumnHeader
            class="createdBy"
            :sort-model="store.devBoms"
            sort-name="authorName"
          >
            Created By
          </ListColumnHeader>
          <ListColumnHeader
            class="timeAgo"
            :sort-model="store.devBoms"
            sort-name="modifyStamp"
          >
            Last Edited
          </ListColumnHeader>
          <ListColumnHeader class="notes">
            Notes
          </ListColumnHeader>
          <ListColumnHeader class="menu" />
        </div>
        <div
          v-if="isEmpty"
          class="empty"
        >
          <p>
            No matching Development BOMs found.
          </p>
        </div>
        <div
          v-if="isError"
          class="error"
        >
          <p>
            We're sorry. There was a problem trying to find Development BOMs.
          </p>
          <p>
            You may want to try changing your search criteria.
          </p>
        </div>
        <Scroller v-else-if="isReady">
          <DevBomItem
            v-for="item in items"
            :key="item.bompartId"
            :item="item"
          />
        </Scroller>
        <Spinner
          v-else
          class="stretch"
        />
      </div>
    </div>

    <Pagination
      v-if="isReady && !isEmpty"
      :model="store.devBoms"
      name="currentPage"
      :item-count="store.devBoms.totalItemsCount"
      :items-per-page="store.devBoms.perPage"
      :current-page="store.devBoms.currentPage"
    />

    <Modal
      name="delete-devbom"
      title="Confirm Removal"
    >
      <DevBomConfirmRemoval />
    </Modal>

    <Modal
      name="share-devbom"
      title="Confirm Share"
    >
      <DevBomConfirmShare />
    </Modal>
  </div>
</template>

<script>
import DevBomItem from './DevBomItem.vue'
import DevBomConfirmRemoval from './DevBomConfirmRemoval.vue'
import DevBomConfirmShare from './DevBomConfirmShare.vue'
import ListColumnHeader from '../ListColumnHeader.vue'
import Modal from '../Modal.vue'
import Pagination from '../Pagination.vue'
import Scroller from '../Scroller.vue'
import Spinner from '../Spinner.vue'
export default {
  components: {
    DevBomConfirmRemoval,
    DevBomItem,
    DevBomConfirmShare,
    ListColumnHeader,
    Modal,
    Pagination,
    Scroller,
    Spinner
  },
  inject: ['store'],
  computed: {
    isEmpty () {
      return this.isReady && this.items.length === 0
    },
    isError () {
      return this.store.devBoms.$asyncComputed.response.error
    },
    isReady () {
      return this.store.devBoms.isReady
    },
    items () {
      return this.store.devBoms.items.filter((bom) => {
        return !bom.isDeleted
      })
    },
    selectAll: {
      get () {
        return this.store.devBoms.allSelected
      },
      set (value) {
        this.store.devBoms.allSelected = value
      }
    }
  }
}
</script>

<style lang="scss">
.DevBomList {
  @include centerColumn;

  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
  align-self: center;
  flex-shrink: 2;
  flex-grow: 0;
  overflow: hidden;

  .Pagination {
    margin-top: 2rem;
    color: $blue;

    svg path {
      fill: $blue;
    }

    .previous,
    .next {
      &:hover {
        color: $gray-sec;

        svg path {
          fill: $gray-sec;
        }
      }
    }
  }

  .card {
    @include cardFull;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-shrink: 1;
  }

  .dataTable {
    @include dataTable();

    .th,
    .tr {
      grid-template-columns: 4rem 21% 15% 20% minmax(0, 1fr) 5rem;
    }
  }

  .ListHeader {
    .ListColumnHeader.title {
      width: 29%;
    }
  }

  .empty,
  .error {
    height: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
