<template>
  <div
    class="FindMaterialSlider"
    :class="isOpen"
  >
    <div class="sliderHeader">
      <h3 v-if="store.findMaterial.pane === 'materialCode'">
        Find {{ sectionLabel }}
      </h3>
      <a
        v-if="store.findMaterial.pane === 'colorSupplier'"
        @click="onBackClick"
      >
        <ChevronLeftIcon />
        <span>Back</span>
      </a>
      <button @click="onCloseClick">
        <CloseIcon />
      </button>
    </div>
    <FindMaterial :section-name="sectionName" />
  </div>
</template>

<script>
import CloseIcon from '../icons/CloseIcon.vue'
import FindMaterial from './FindMaterial.vue'
import ChevronLeftIcon from '../icons/ChevronLeftIcon.vue'

export default {
  components: {
    CloseIcon,
    FindMaterial,
    ChevronLeftIcon
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      default: 'Material'
    }
  },
  computed: {
    isOpen () {
      if (this.store.findMaterial.isOpen) {
        return 'open'
      }
      return ''
    },
    sectionLabel () {
      return this.store.codes.sections[this.sectionName]
    }
  },
  methods: {
    onCloseClick () {
      this.store.findMaterial.close()
    },
    onBackClick () {
      this.store.findMaterial.previousPane()
    }
  }
}
</script>

<style lang="scss">
.FindMaterialSlider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background-color: $page-background;
  transition: left 200ms ease;
  padding-right: 2.5rem;
  border-right: 1px solid $gray-lines;
  box-sizing: border-box;

  &.open {
    left: 0%;
  }

  .sliderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      text-transform: capitalize;
    }

    a {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }

    svg {
      path {
        fill: $blue;
      }
    }
  }
}
</style>
