<template>
  <div class="Review">
    <h4 class="message">
      Are you sure you want to apply the following
      Development BOM sections to the selected PC9s?
    </h4>

    <div class="reviewContent">
      <h3>
        Sections
      </h3>
      <ReviewSectionsList
        :sections="store.modals.params.sections"
      />

      <h3>
        PC9s
      </h3>

      <PC9FilterList
        :model="assign.reviewSelections"
        :options="assign.reviewSelections.statusResults"
        :sections="store.modals.params.sections"
        :show-warnings="true"
        :is-loading="assign.reviewSelections.isLoading"
      />
    </div>

    <div
      v-if="assign.reviewSelections.showErrors"
      class="error"
    >
      Please select at least one PC9 Code.
    </div>

    <div class="footer">
      <ModalLink
        name="assign-bom-choose-sections"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div class="ModalButtonsWrapper">
        <SelectAllLink
          :is-deselect-mode="assign.reviewSelections.allSelected"
          :on-select-all="assign.reviewSelections.selectAll"
          :on-deselect-all="assign.reviewSelections.deselectAll"
        />
        <a
          class="button"
          @click="onApplyClick"
        >
          Apply
        </a>
      </div>
    </div>

    <div
      v-if="assign.reviewSelections.isLoading"
      class="loading"
    >
      <Spinner class="stretch" />
    </div>

    <div
      v-if="assign.reviewSelections.isSubmitting"
      class="submitting"
    >
      <Spinner />
      <h2>Assigning {{ selectedCount }} PC9s…</h2>
    </div>
  </div>
</template>

<script>
import BackLink from '../BackLink.vue'
import ModalLink from '../ModalLink.vue'
import PC9FilterList from './PC9FilterList.vue'
import ReviewSectionsList from './ReviewSectionsList.vue'
import Spinner from '../Spinner.vue'
import SelectAllLink from '../SelectAllLink.vue'

export default {
  components: {
    BackLink,
    ModalLink,
    ReviewSectionsList,
    PC9FilterList,
    Spinner,
    SelectAllLink
  },
  inject: [ 'store' ],
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      return {
        ...this.store.modals.params
      }
    },
    selectedCount () {
      return this.assign.reviewSelections.selectedCodes.length
    }
  },
  mounted () {
    const { modals } = this.store
    const { reviewSelections } = this.assign

    reviewSelections.reset({
      bomId: modals.params.bomId,
      sections: modals.params.sections,
      seasonId: modals.params.seasonId,
      pc9Codes: modals.params.selectedCodes
    })
  },
  methods: {
    onApplyClick () {
      this.submit()
    },
    showErrorsModal (response) {
      const { modals, assign } = this.store
      const params = {
        response,
        sections: modals.params.sections,
        pc9Codes: assign.reviewSelections.selectedCodes
      }
      modals.show('assign-bom-errors', params)
    },
    async submit () {
      const { reviewSelections } = this.assign
      const { assign, modals, router } = this.store
      if (reviewSelections.isValid) {
        try {
          const response = await reviewSelections.submit()
          const success = response.every((result) => {
            return result.status === 200
          })
          if (success) {
            const PC9Code = assign.reviewSelections.selectedCodes.join(',')
            const { seasonId } = modals.params
            modals.hide()
            router.push({
              path: '/search-results/fabric',
              query: {
                PC9Code,
                seasonId
              }
            })
          } else {
            this.showErrorsModal(response)
          }
        } catch (error) {
          this.showErrorsModal([])
        }
      } else {
        reviewSelections.showErrors = true
      }
    }
  }
}
</script>

<style lang="scss">
.Review {
  .message {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  .reviewContent {
    h3 {
      font-size: 1.8rem;
      margin-bottom: 24px;
    }

    .ReviewSectionsList {
      margin-bottom: 3rem;
    }

    .PC9FilterList {
      max-height: 360px;
      overflow-y: auto;
    }
  }

  .error {
    color: $red;
    text-align: right;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  .loading,
  .submitting {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-overlay;
  }

  .submitting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;

    .Spinner {
      margin-bottom: 2rem;
    }
  }
}
</style>
