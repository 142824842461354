<template>
  <ContextMenu
    v-if="option.isMissing"
    class="MissingPC9CodesWarning"
    :x-offset="-24"
    :y-offset="10"
    :arrow-offset="24"
    :use-hover="true"
  >
    <template v-slot:button>
      <BangCircleIcon />
    </template>

    <template v-slot:default>
      <div class="missingCodesWarningContextPopup">
        This PC9 Code was not found for the selected season.
      </div>
    </template>
  </ContextMenu>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'
import ContextMenu from '../ContextMenu.vue'

export default {
  inject: [ 'store' ],
  components: {
    BangCircleIcon,
    ContextMenu
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.MissingPC9CodesWarning {
  margin-left: 1rem;
}

.context-menu-portal-target {
  .missingCodesWarningContextPopup {
    padding: 2rem;
  }
}
</style>
