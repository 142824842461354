<template>
  <SubHeader class="DevBomsHeader">
    <template v-slot:leftSide>
      <h3>Development BOMs</h3>
      <DevBomsShowToggle />
    </template>

    <template v-slot:rightSide>
      <div class="devBomsControls">
        <div
          class="slider search"
          :class="{ active: !anySelected }"
        >
          <input
            type="text"
            placeholder="Search development BOMs by name or note"
            :value="title"
            @input="onSearchInput"
          >
          <ModalLink
            name="create-dev-bom"
            :params="{ createType: 'copyPC9' }"
            class="button create"
          >
            Copy From PC9 BOM
          </ModalLink>

          <ModalLink
            name="create-dev-bom"
            :params="{ createType: 'fromScratch' }"
            class="button create CreateDevBomButton"
          >
            Create From Scratch
          </ModalLink>
        </div>

        <div
          class="slider bulk"
          :class="{ active: anySelected }"
        >
          <ModalLink
            name="delete-devbom"
            class="button delete clearDark"
            :params="bomItems"
          >
            Delete
          </ModalLink>

          <ModalLink
            name="share-devbom"
            class="button share"
            :params="bomItems"
          >
            Share
          </ModalLink>
        </div>
      </div>
      <Modal
        name="create-dev-bom"
        title="Create new Development BOM"
      >
        <CreateDevBom />
      </Modal>
    </template>
  </SubHeader>
</template>

<script>
import CreateDevBom from '../CreateDevBom/CreateDevBom.vue'
import debounce from 'debounce-promise'
import DevBomsShowToggle from './DevBomsShowToggle.vue'
import Modal from '../Modal.vue'
import ModalLink from '../ModalLink.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    CreateDevBom,
    DevBomsShowToggle,
    Modal,
    ModalLink,
    SubHeader
  },
  inject: ['store'],
  computed: {
    anySelected () {
      return this.store.devBoms.anySelected
    },
    bomItems () {
      return {
        boms: this.store.devBoms.selectedBoms
      }
    },
    title () {
      return this.store.devBoms.title
    }
  },
  methods: {
    onSearchInput: debounce(function (event) {
      this.store.devBoms.title = event.target.value
    }, 300)
  }
}
</script>

<style lang="scss">
.DevBomsHeader {
  flex-shrink: 0;
  flex-grow: 0;

  .button {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .devBomsControls {
    height: 4rem;
    overflow: hidden;
  }

  .slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 4rem;
    overflow: hidden;
    transition: transform 200ms ease;

    input[type="text"] {
      width: 50rem;
      margin-right: 2rem;
    }

    .button {
      height: 4rem;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    .button.delete {
      margin-right: 2rem;
    }

    &.search {
      transform: translateY(-100%);
    }

    &.search.active {
      transform: translateY(0%);
    }

    &.bulk {
      transform: translateY(100%);
    }

    &.bulk.active {
      transform: translateY(-100%);
    }
  }
}
</style>
