<template>
  <div class="BomReviewList">
    <Spinner
      v-if="boms && !boms.length"
      class="stretch"
    />

    <BomReviewBom
      v-for="bom in boms"
      :key="bom.bompartId"
      :bom="bom"
    />
  </div>
</template>

<script>
import BomReviewBom from './BomReviewBom.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    BomReviewBom,
    Spinner
  },
  inject: [ 'store' ],
  computed: {
    boms () {
      return this.store.bomReview.boms
    }
  }
}
</script>

<style lang="scss">
.BomReviewList {
  padding-left: 4rem;
  padding-right: 2rem;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}
</style>
