<template>
  <div class="AssignErrors">
    <div v-if="response && response.length">
      <h3>These PC9s were not assigned:</h3>
      <ul
        v-for="status in response"
        :key="status.PC9Code"
        class="pc9Codes"
      >
        <li>
          <span>{{ status.PC9Code }} - </span>
          <span class="errorMessage">
            {{ status.errorMsg }}
          </span>
        </li>
      </ul>
    </div>
    <div v-else>
      <h3>There was an error assigning sections to PC9s.</h3>
      <div>
        PC9 data may be in an unexpected state.
        Please review your data for accuracy:
      </div>
      <h4>Sections</h4>
      <ReviewSectionsList :sections="sections" />
      <h4>PC9s</h4>
      <ul
        v-for="pc9Code in pc9Codes"
        :key="pc9Code"
        class="pc9Codes"
      >
        <li>{{ pc9Code }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ReviewSectionsList from './ReviewSectionsList'

export default {
  inject: [ 'store' ],
  components: {
    ReviewSectionsList
  },
  computed: {
    pc9Codes () {
      return this.store.modals.params.pc9Codes
    },
    response () {
      return this.store.modals.params.response
    },
    sections () {
      return this.store.modals.params.sections
    }
  }
}
</script>

<style lang="scss">
.AssignErrors {
  h3 {
    margin-bottom: 1rem;
  }

  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    color: $blue;
    font-size: 1.5rem;

    .errorMessage {
      color: $red;
    }
  }
}
</style>
