<template>
  <div class="CreateDevBomCopyPC9">
    <div class="copy-pc9">
      <h3>Copy from PC9 BOM</h3>
      <p>Select the season and enter the PC9 Code for the existing BOM</p>

      <form
        @submit="onSubmit"
      >
        <div class="field">
          <BomSearchSeasonField
            :model="store.bomSearch"
          />
        </div>

        <div class="field">
          <label>PC9 Code</label>
          <AutoComplete
            type="PC9/code"
            value-key="lscoColorwayCode"
            :model="store.copyFromPC9"
            name="PC9Code"
            :show-label="false"
            :allow-viewall="true"
            focus
          />
        </div>

        <div v-if="store.copyFromPC9.showSearchError">
          That BOM was not found. Please check your search criteria
          and try again.
        </div>

        <p>
          <button
            type="submit"
            class="button"
          >
            Find PC9 BOM
          </button>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'
import BomSearchSeasonField from '../BomSearch/BomSearchSeasonField.vue'

export default {
  components: {
    AutoComplete,
    BomSearchSeasonField
  },
  inject: [ 'store' ],
  mounted () {
    this.store.copyFromPC9.showSearchError = false
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.store.copyFromPC9.search()
    }
  }
}
</script>

<style lang="scss">
.CreateDevBomCopyPC9 {
  .field {
    margin-top: 3rem;
  }

  .button {
    margin-top: 2rem;
  }
}
</style>
