<template>
  <div
    class="ListColumnHeader td"
    @click="onClick"
  >
    <slot />
    <div
      v-if="isSortAsc"
      class="sort asc"
    >
      <ChevronDownIcon />
    </div>
    <div
      v-if="isSortDesc"
      class="sort desc"
    >
      <ChevronDownIcon />
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
export default {
  components: {
    ChevronDownIcon
  },
  inject: [ 'store' ],
  props: {
    sortModel: {
      type: Object,
      default: () => { return {} }
    },
    sortName: {
      type: String,
      default: ''
    }
  },
  computed: {
    allowSort () {
      return this.sortModel.hasOwnProperty('sortBy')
    },
    isSort () {
      return this.sortBy.startsWith(this.sortName)
    },
    isSortAsc () {
      return this.isSort && this.sortBy.endsWith('ASC')
    },
    isSortDesc () {
      return this.isSort && this.sortBy.endsWith('DESC')
    },
    sortBy: {
      get () {
        return this.sortModel.sortBy || ''
      },
      set (value) {
        this.sortModel.sortBy = value
      }
    }
  },
  methods: {
    onClick () {
      if (this.allowSort) {
        if (this.isSortAsc) {
          this.sortBy = `${this.sortName} DESC`
        } else {
          this.sortBy = `${this.sortName} ASC`
        }
      }
    }
  }
}
</script>

<style lang="scss">
.ListColumnHeader {
  font-size: 1.2rem;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  font-family: $ff-sec;
  letter-spacing: 0.5px;
  color: $gray-sec;
  display: flex;
  cursor: pointer;

  .sort {
    margin-left: 0.5rem;

    &.asc {
      svg {
        transform: rotateX(180deg);
      }
    }
  }
}
</style>
