<template>
  <form
    class="EnterPC9s"
    @submit.prevent="onSubmit"
  >
    <section>
      <div class="gutter">
        <div class="stepNumber">
          1
        </div>
      </div>
      <div class="whatSeason">
        <h4>What season?</h4>
        <SeasonSelector
          :model="assign.chooseSeason"
        />
      </div>
    </section>

    <section class="enterCodeSection">
      <div class="gutter">
        <div class="stepNumber">
          2
        </div>
      </div>

      <div class="enterCodes">
        <h4>Enter the PC9 code(s)</h4>
        <p>If multiple, enter as comma or space separated values</p>
        <AutoComplete
          type="PC9/code"
          value-key="lscoColorwayCode"
          :model="assign.enterPC9Codes"
          name="codesText"
          :season-id="assign.chooseSeason.seasonId"
          :show-label="false"
        />
      </div>
    </section>

    <div
      v-if="assign.enterPC9Codes.showErrors"
      class="error"
    >
      Please select a season, and enter one or more PC9 codes.
    </div>

    <div class="footer">
      <ModalLink
        class="backLink"
        name="assign-bom-choose-enter-or-lookup"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div>
        <button
          class="button"
          type="submit"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'
import BackLink from '../BackLink.vue'
import ModalLink from '../ModalLink.vue'
import SeasonSelector from '../SeasonSelector.vue'

export default {
  components: {
    AutoComplete,
    BackLink,
    ModalLink,
    SeasonSelector
  },
  inject: [ 'store' ],
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        seasonId: assign.chooseSeason.seasonId
      }
    },
    nextLinkParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        seasonId: assign.chooseSeason.seasonId,
        PC9Code: assign.enterPC9Codes.PC9Code
      }
    }
  },
  mounted () {
    const { assign, modals } = this.store
    assign.enterPC9Codes.showErrors = false
    assign.enterPC9Codes.PC9Code = modals.params.PC9Code
    assign.chooseSeason.seasonId = modals.params.seasonId
  },
  methods: {
    onSubmit () {
      const { assign, modals } = this.store
      assign.enterPC9Codes.showErrors = false
      if (assign.enterPC9Codes.isValid) {
        modals.show('assign-bom-pc9-results', this.nextLinkParams)
      } else {
        assign.enterPC9Codes.showErrors = true
      }
    }
  }
}
</script>

<style lang="scss">
.EnterPC9s {
  @include assignFindPC9Codes();
}
</style>
