<template>
  <ContextMenu
    v-if="warningSections.length"
    class="ReviewSectionsWarning"
    :x-offset="-24"
    :y-offset="10"
    :arrow-offset="24"
    :use-hover="true"
  >
    <template v-slot:button>
      <BangCircleIcon />
    </template>

    <template v-slot:default>
      <div class="assignPC9WarningMessage">
        <span>
          PC9 contains materials that will be replaced by the
          following Development BOM sections:
        </span>

        <ReviewSectionsList
          :sections="warningSections"
        />
      </div>
    </template>
  </ContextMenu>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'
import ContextMenu from '../ContextMenu.vue'
import ReviewSectionsList from './ReviewSectionsList.vue'

export default {
  inject: [ 'store' ],
  components: {
    BangCircleIcon,
    ContextMenu,
    ReviewSectionsList
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    sections: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    hasWarnings () {
      return this.warningSections.length
    },
    warningSections () {
      return this.sections.reduce((acc, sectionKey) => {
        const section = this.option.sections[sectionKey] || { isEmpty: true }
        if (!section.isEmpty) {
          acc.push(sectionKey)
        }
        return acc
      }, [])
    }
  }
}
</script>

<style lang="scss">
.ReviewSectionsWarning {
  height: 100%;
  margin-left: 1rem;

  .buttonWrapper {
    cursor: default;
  }
}

.context-menu-portal-target {
  .assignPC9WarningMessage {
    max-width: 320px;
    padding: 2rem;

    .ReviewSectionsList {
      margin-top: 2rem;
    }
  }
}
</style>
