<template>
  <div class="LineItemContextMenu">
    <ContextMenu
      :x-offset="-130"
      :arrow-offset="139"
    >
      <template v-slot:default>
        <ul>
          <li v-if="showMaterialOptions">
            <a
              class="clearContentLink"
              @click="onClearContentClick"
            >Clear Content</a>
          </li>

          <li>
            <a
              class="removeLineItemLink"
              @click="onRemoveLineItemClick"
            >Remove line item</a>
          </li>
        </ul>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import ConfirmRemoval from './dialogs/ConfirmRemoval.vue'
import ContextMenu from '../ContextMenu.vue'

export default {
  components: {
    ContextMenu
  },
  inject: ['store'],
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    showMaterialOptions: {
      type: Boolean,
      default: true
    },
    subsectionTitle: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  computed: {
    bomDescription () {
      if (this.nonSeasonalLooks.length === 1) {
        const bom = this.nonSeasonalLooks[0]
        return bom.colorwayName || this.store.bomEditor.devBomTitle
      }
      return `${this.nonSeasonalLooks.length} BOMs`
    },
    bomLinkIds () {
      return this.nonSeasonalLooks.map(bom => {
        return bom.bomlinkId
      })
    },
    materialDescription () {
      return this.lineItem.materialDescription || 'The selected Line Item'
    },
    removeParams () {
      return {
        subsectionTitle: this.subsectionTitle,
        lineItem: this.lineItem
      }
    },
    // return selected unlocked BOM's
    selectedBoms () {
      const { bomEditor } = this.store
      const selectedBoms = this.lineItem.bompartInfo.filter(bom => {
        return bomEditor.selectedBomIds.includes(bom.bompartId)
      })
      return selectedBoms.filter(bom => {
        return bomEditor.checkMRPlock(bom.bompartId)
          ? undefined
          : bom.bompartId
      })
    },
    // to filter out Non-seasonal looks only
    // iterate through selected bom to filter out those with no seasonal ID
    nonSeasonalLooks () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      if (validLineItems.includes(this.component)) {
        return this.selectedBoms.filter(bom => bom.seasonalLookId === null)
      } else {
        return this.selectedBoms
      }
    }
  },
  methods: {
    async onClearContentClick () {
      const { confirmationDialog } = this.store
      const result = await confirmationDialog.show({
        confirmClass: 'remove',
        confirmText: 'Remove',
        component: ConfirmRemoval,
        props: {
          target: this.lineItem.materialDescription,
          parent: this.subsectionTitle
        },
        title: 'Confirm Removal'
      })

      if (result) {
        const { bomEditor, bomEditorApi, router } = this.store
        await bomEditorApi.removeMaterial({
          lineItemIds: this.bomLinkIds,
          materialDescription: this.lineItem.materialDescription
        })
        router.setHash({
          showLineItem: this.bomLinkIds[0]
        })
        bomEditor.refresh()
      }
    },
    async onRemoveLineItemClick () {
      const { confirmationDialog } = this.store
      const result = await confirmationDialog.show({
        confirmClass: 'remove',
        confirmText: 'Remove',
        component: ConfirmRemoval,
        props: {
          target: this.materialDescription,
          parent: this.bomDescription
        },
        title: 'Confirm Removal'
      })
      if (result) {
        const { bomEditor, bomEditorApi, router } = this.store
        await bomEditorApi.removeLineItem({
          lineItemIds: this.bomLinkIds,
          description: this.materialDescription
        })
        router.setHash({
          showLineItem: undefined
        })
        bomEditor.refresh()
      }
    }
  }
}
</script>
