<template>
  <component :is="iconComponent" />
</template>

<script>
import icons from './icons/sections/all.js'

export default {
  props: {
    section: {
      type: String,
      required: true
    }
  },
  computed: {
    iconComponent () {
      return icons[this.section]
    }
  }
}
</script>
