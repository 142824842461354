<template>
  <div class="FindMaterialFabricSearch">
    <div class="row">
      <div class="col">
        <label>Sundry Type</label>
        <select v-model="sundryType">
          <option
            v-for="option in sundryTypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div class="col">
        <label>Sundry Subtype</label>
        <select v-model="sundrySubtype">
          <option
            v-for="option in sundrySubtypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="sundrySearchControls">
      <button
        class="button"
        @click="onSundrySearchClick"
      >
        Search
      </button>
    </div>
  </div>
</template>

<script>
const anyOption = {
  value: '',
  label: 'Any'
}

export default {
  inject: [ 'store' ],
  computed: {
    sundrySubtype: {
      get () {
        return this.model.sundrySubtype
      },
      set (value) {
        this.model.sundrySubtype = value
      }
    },
    sundrySubtypeOptions () {
      return [ anyOption, ...this.store.codes.sundrySubtype ]
    },
    sundryType: {
      get () {
        return this.model.sundryType
      },
      set (value) {
        this.model.sundryType = value
      }
    },
    sundryTypeOptions () {
      return [ anyOption, ...this.store.codes.sundryType ]
    },
    model () {
      return this.store.chooseMaterialCode
    }
  },
  methods: {
    onSundrySearchClick () {
      const { chooseMaterialCode } = this.store
      chooseMaterialCode.skip = 0
      chooseMaterialCode.fetch()
    }
  }
}
</script>

<style lang="scss">
.FindMaterialFabricSearch {
  .row {
    display: flex;
    margin-top: 1rem;

    .col {
      flex-basis: 50%;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .sundrySearchControls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
</style>
