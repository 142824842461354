<template>
  <div class="CreateDevBomFromScratch">
    <h3>Create From Scratch</h3>

    <div>
      <label>Title (optional)</label>
      <input
        v-model="title"
        type="text"
        placeholder="Untitled"
      >
    </div>

    <div>
      <label>Notes (optional)</label>
      <textarea
        v-model="notes"
        rows="3"
      />
    </div>

    <button
      class="button"
      @click="onCreateClick"
    >
      Create New Development BOM
    </button>

    <Spinner
      v-if="store.createDevBomFromScratch.isLoading"
    />

    <div
      v-if="store.createDevBomFromScratch.isError"
      class="error"
    >
      We're sorry. There was an error creating this DevBom.
    </div>
  </div>
</template>

<script>
import Spinner from '../Spinner.vue'

export default {
  components: {
    Spinner
  },
  inject: [ 'store' ],
  computed: {
    notes: {
      get () {
        return this.store.createDevBomFromScratch.note
      },
      set (value) {
        this.store.createDevBomFromScratch.note = value
      }
    },
    title: {
      get () {
        return this.store.createDevBomFromScratch.title
      },
      set (value) {
        this.store.createDevBomFromScratch.title = value
      }
    }
  },
  mounted () {
    this.store.createDevBomFromScratch.reset()
  },
  methods: {
    async onCreateClick () {
      const response = await this.store.createDevBomFromScratch.create()
      const query = {
        id: response.bompartId
      }
      this.store.router.push({
        path: '/dev-bom/fabric',
        query
      })
      this.store.clickStream.obEvent('Create BOM', 'Create New Development BOM')
    }
  }
}

</script>

<style lang="scss">
.CreateDevBomFromScratch {
  label {
    margin-top: 2rem;
  }

  input,
  textarea {
    width: 50rem;
  }

  .button {
    margin-top: 2rem;
  }
}
</style>
