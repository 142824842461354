<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '8px 8px 8px 8px'
      }
    }"
    class="MaterialBomList"
  >
    <MaterialBomItem
      v-for="bom in sortedBoms"
      :key="bom.bomlinkId"
      :line-item="lineItem"
      :bom="bom"
      :is-visible="isVisible"
      :section-name="sectionName"
      :component="component"
    />
  </div>
</template>

<script>
import MaterialBomItem from './MaterialBomItem.vue'

export default {
  components: {
    MaterialBomItem
  },
  inject: ['store'],
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  computed: {
    boms () {
      return this.lineItem.bompartInfo
    },
    selectedBoms () {
      const { bomEditor } = this.store
      return this.boms.filter(bom => {
        return bomEditor.filters.includes(bom.bompartId)
      })
    },
    sortedBoms () {
      return [...this.selectedBoms].sort((lhs, rhs) => {
        return lhs.colorwayName.localeCompare(rhs.colorwayName)
      })
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
    }
  }
}
</script>

<style lang="scss">
.MaterialBomList {
  display: grid;
  margin: 1rem 0 0;
  // `minmax` is required to prevent the columns from expanding the grid
  grid-template-columns: repeat(auto-fit, minmax(36ch, auto));
  justify-items: start;
  justify-content: start;
  column-gap: 8px;
  row-gap: 8px;

  // Set some default height and width for visibilityChanged events
  min-height: 8px;
  min-width: 8px;
}
</style>
