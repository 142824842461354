<template>
  <div class="MSCCardList">
    <div class="row">
      <label>Filter by Color</label>
      <input
        ref="color"
        v-model="colorFilter"
        type="text"
        placeholder="Type a color name or code"
      >
    </div>
    <div class="row">
      <label>Choose a Supplier</label>
      <select
        v-model="supplierMat"
      >
        <option
          v-for="supplierItem in supplierOptions"
          :key="supplierItem.supplierCode"
          :value="supplierItem.supplierCode"
        >
          {{ supplierItem.supplierName }}
        </option>
      </select>
    </div>
    <hr>

    <Spinner
      v-if="!store.findMaterial.isColorOptionsReady"
      class="stretch NextPageMaterialsSpinner"
    />

    <div v-if="store.findMaterial.isColorOptionsReady">
      <drag
        v-for="item in items"
        :key="item.colorCode"
        class="card"
        :transfer-data="transferData(item)"
      >
        <GrabHandleIcon class="icon" />
        <div class="text">
          <div class="material">
            {{ material }}
          </div>
          <div class="supplier">
            {{ item.supplierName }}
          </div>
          <div class="color">
            {{ item.colorName }}
          </div>
        </div>

        <!--This is ensuring drag image is same as drag object as opposed to the browser default-->
        <template slot="image">
          <div class="card">
            <GrabHandleIcon class="icon" />
            <div class="text">
              <div class="material">
                {{ material }}
              </div>
              <div class="supplier">
                {{ item.supplierName }}
              </div>
              <div class="color">
                {{ item.colorName }}
              </div>
            </div>
          </div>
        </template>
      </drag>

      <div v-if="store.findMaterial.isColorOptionsReady">
        <Pagination
          :items-per-page="store.findMaterial.perPage"
          :model="store.findMaterial"
          name="currentPage"
          :item-count="total"
          :current-page="store.findMaterial.currentPage"
          :show-page-numbers="false"
          class="MaterialResults"
          type="materials"
        />
      </div>
      <Spinner
        v-else
        class="stretch"
      />
    </div>
  </div>
</template>

<script>
import GrabHandleIcon from '../icons/GrabHandleIcon.vue'
import Spinner from '../Spinner.vue'
import Pagination from '../Pagination.vue'

export default {
  components: {
    GrabHandleIcon,
    Spinner,
    Pagination
  },
  inject: [ 'store' ],
  computed: {
    colorFilter: {
      get () {
        return this.store.findMaterial.colorFilter
      },
      set (value) {
        this.store.findMaterial.currentPage = 1
        this.store.findMaterial.colorFilter = value
      }
    },
    supplierMat: {
      get () {
        return this.store.findMaterial.supplier
      },
      set (value) {
        this.store.findMaterial.currentPage = 1
        this.store.findMaterial.supplier = value
      }
    },
    supplierOptions () {
      return this.store.findMaterial.supplierOptions
    },
    items () {
      return this.store.findMaterial.results
    },
    material () {
      return this.store.findMaterial.currentMaterialName
    },
    supplier () {
      return this.store.findMaterial.currentSupplierName
    },
    total () {
      return this.store.findMaterial.total
    }
  },
  watch: {
    material (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.store.findMaterial.currentPage = 1
        this.$refs.color.focus()
      }
    }
  },
  methods: {
    onNextClick () {
      this.store.findMaterial.gotoNextColorPage()
    },
    onPreviousClick () {
      this.store.findMaterial.gotoPreviousColorPage()
    },
    transferData (item) {
      return {
        material: this.store.findMaterial.material,
        materialName: this.material,
        supplier: item.supplierCode,
        color: item.colorCode
      }
    }
  }
}
</script>

<style lang="scss">
.MSCCardList {
  hr {
    border: 0;
    height: 0;
    border-top: 1px solid $gray-card-border;
    margin: 24px 0;
  }

  .MaterialResults {
    margin: 32px 0;
  }

  .card {
    @include card;

    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    cursor: grab;
    padding: 10px 15px;

    .icon {
      margin-right: 2rem;
    }

    .material,
    .supplier {
      margin-bottom: 4px;
    }
  }

  .NextPageMaterialsSpinner {
    margin-top: 64px;
  }
}
</style>
