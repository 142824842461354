<template>
  <div class="BomSearchSeasonField">
    <label>
      <span>
        Season*
      </span>
      <a
        class="seasonListSwitcher"
        @click="fullListClick"
      >
        <span
          v-if="showSwitchLink"
        >
          <span v-if="showAllSeasons">
            use favorites list
          </span>
          <span v-else>
            use full list
          </span>
        </span>
      </a>
    </label>
    <select v-model="value">
      <option
        v-for="item in store.bomSearch.seasonOptions"
        :key="item.seasonId"
        :value="item.seasonId"
      >
        {{ item.seasonName }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    showAllKey: {
      type: String,
      default: 'showAllSeasons'
    },
    valueKey: {
      type: String,
      default: 'seasonId'
    }
  },
  computed: {
    showAllSeasons: {
      get () {
        return this.model[this.showAllKey]
      },
      set (value) {
        this.model[this.showAllKey] = value
      }
    },
    showSwitchLink () {
      return !this.store.favoriteSeasons.isEmpty
    },
    value: {
      get () {
        return this.model[this.valueKey]
      },
      set (value) {
        this.model[this.valueKey] = value
      }
    }
  },
  inject: [ 'store' ],
  methods: {
    fullListClick () {
      this.showAllSeasons = !this.showAllSeasons
    }
  }
}
</script>

<style lang="scss">
.BomSearchSeasonField {
  label {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
</style>
