<template>
  <div class="ReviewSectionsList">
    <ul
      v-for="sectionKey in sections"
      :key="sectionKey"
    >
      <li>{{ sectionLabel(sectionKey) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  props: {
    sections: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    sectionLabel (sectionKey) {
      return this.store.codes.sections[sectionKey]
    }
  }
}
</script>

<style lang="scss">
.ReviewSectionsList {
  ul {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 1.5rem;

    li {
      margin-bottom: 1rem;
    }
  }
}
</style>
