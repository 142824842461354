<template>
  <SubHeader class="SearchHeader">
    <template v-slot:leftSide>
      <h3>Search</h3>
      <div class="requiredFields">
        * Required Fields
      </div>
    </template>

    <template v-slot:rightSide>
      <button
        :disabled="store.bomSearch.material.length < 3"
        class="button download"
        @click="onGenerate"
      >
        Where Used Report
        <Spinner
          v-if="store.bomSearch.isLoading"
          color="white"
        />
      </button>
      <button
        :disabled="store.quickBomSearch.seasonId === 'ALL'"
        class="button"
        @click="onSearchClick"
      >
        Search BOMs
      </button>
    </template>
  </SubHeader>
</template>

<script>
import SubHeader from '../SubHeader.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    SubHeader,
    Spinner
  },
  inject: [ 'store' ],
  methods: {
    onSearchClick () {
      this.store.bomSearch.goToResults()
      this.store.clickStream.obEvent('Search', 'Search BOMs')
    },
    onGenerate () {
      this.store.bomSearch.generateReport()
    }
  }
}
</script>

<style lang="scss">
.SearchHeader {
  flex-grow: 0;

  .download.button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Spinner {
      width: 16px;
      height: 16px;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
