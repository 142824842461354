<template>
  <div
    class="PC9FilterItem"
    :class="{ disabled: option.powerCoreIndicator === 'Y' || option.lscoMrpLock === 'Y' }"
  >
    <label>
      <input
        v-model="selected"
        type="checkbox"
        :disabled="option.powerCoreIndicator === 'Y' || option.lscoMrpLock === 'Y'"
      >
      <span :style="{ color: getFontColor()}">{{ option.colorwayName ? option.colorwayName : option.PC9Name }}</span>

      <ReviewSectionsWarning
        :option="option"
        :sections="sections"
      />

      <MissingPC9CodesWarning
        :option="option"
      />

    </label>
  </div>
</template>

<script>
import MissingPC9CodesWarning from './MissingPC9CodesWarning.vue'
import ReviewSectionsWarning from './ReviewSectionsWarning.vue'

export default {
  inject: [ 'store' ],
  components: {
    MissingPC9CodesWarning,
    ReviewSectionsWarning
  },
  props: {
    model: {
      type: Object,
      required: true,
      validator (model) {
        return (
          model.isSelected &&
          model.setSelected
        )
      }
    },
    option: {
      type: Object,
      required: true
    },
    sections: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    selected: {
      get () {
        return this.model.isSelected(this.option.PC9Code)
      },
      set (value) {
        this.model.setSelected(this.option.PC9Code, value)
      }
    }
  },
  methods: {
    getFontColor () {
      if (this.option.powerCoreIndicator === 'Y') {
        return '#c41230'
      } else if (this.option.lscoMrpLock === 'Y') {
        return 'grey'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
.PC9FilterItem {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: $gray;

    input[type="checkbox"] {
      margin-right: 1rem;
      flex-shrink: 0;
    }
  }

  &.disabled {
    label {
      cursor: default;

      input {
        background-color: $gray-tertiary;
      }
    }
  }
}
</style>
