<template>
  <div class="tr">
    <div class="td check">
      <input
        v-model="selected"
        type="checkbox"
      >
    </div>
    <div class="td title">
      <div v-if="showTitleEditor">
        <DevBomTitleEditor
          :item="item"
        />
      </div>
      <router-link
        v-else
        :to="linkTarget"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="td createdBy">
      {{ item.authorName }}
    </div>
    <div class="td timeAgo">
      {{ timeAgo }}
    </div>
    <div class="td notes">
      <DevBomNoteEditor :item="item" />
    </div>
    <div class="td menu">
      <DevBomContextMenu :item="item" />
    </div>
  </div>
</template>

<script>
import DevBomContextMenu from './DevBomContextMenu.vue'
import DevBomNoteEditor from './DevBomNoteEditor.vue'
import DevBomTitleEditor from '../BomEditor/DevBomTitleEditor.vue'
import time from '../../util/time.js'

export default {
  components: {
    DevBomContextMenu,
    DevBomNoteEditor,
    DevBomTitleEditor
  },
  inject: [ 'store' ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    linkTarget () {
      return {
        path: '/dev-bom',
        query: {
          id: this.item.bompartId
        }
      }
    },
    selected: {
      get () {
        return this.store.devBoms.selected[this.item.bompartId]
      },
      set (value) {
        this.store.devBoms.setItemSelection(this.item.bompartId, value)
      }
    },
    showTitleEditor () {
      const { devBomRename } = this.store
      return (
        devBomRename.isEditing &&
        devBomRename.bomId === this.item.bompartId
      )
    },
    timeAgo () {
      return time.ago(this.item.modifyStamp)
    }
  }
}
</script>
