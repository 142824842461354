<template>
  <form
    class="LookUpPC9CodesAttributes"
    @submit.prevent="onSubmit"
  >
    <section class="seasonSection">
      <div class="gutter">
        <div class="stepNumber">
          1
        </div>
      </div>
      <div class="seasonName">
        <h4>{{ seasonName }}</h4>
      </div>
    </section>

    <section class="pc5Section">
      <div class="gutter">
        <div class="stepNumber">
          2
        </div>
      </div>
      <div class="limitToPC5">
        <h4>
          <span>
            Limit to specific PC5 -
          </span>
          <span>
            <span v-if="store.modals.params.PC5Code.length">
              {{ store.modals.params.PC5Code }}
            </span>
            <span v-else>
              No
            </span>
          </span>
        </h4>
      </div>
    </section>

    <section>
      <div class="gutter">
        <div class="stepNumber">
          3
        </div>
      </div>
      <div class="productAttributes">
        <h4>Attributes</h4>
        <div class="checks">
          <CheckOptions
            class="prodPricePos"
            header="Product Price Positioning"
            :options="store.codes.prodPricePos"
            :model="assign.lookup"
            name="prodPricePos"
          />

          <CheckOptions
            class="prodType"
            header="Product Type"
            :options="store.codes.prodType"
            :model="assign.lookup"
            name="prodType"
          />
        </div>
      </div>
    </section>

    <div
      v-if="assign.lookup.showErrors"
      class="error"
    >
      Please enter a PC5 code
    </div>

    <div class="footer">
      <ModalLink
        class="backLink"
        name="assign-bom-look-up-pc9s-pc5"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div>
        <button
          class="button"
          type="submit"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import BackLink from '../BackLink.vue'
import CheckOptions from '../CheckOptions.vue'
import ModalLink from '../ModalLink.vue'

export default {
  inject: [ 'store' ],
  components: {
    BackLink,
    CheckOptions,
    ModalLink
  },
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { params } = this.store.modals
      return {
        ...params
      }
    },
    nextParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        prodPricePos: assign.lookup.prodPricePos,
        prodType: assign.lookup.prodType
      }
    },
    seasonName () {
      const { modals, seasons } = this.store
      const season = seasons.find(modals.params.seasonId)
      return season.seasonName
    }
  },
  mounted () {
    const { assign, modals } = this.store
    assign.lookup.PC5Code = modals.params.PC5Code || ''
    assign.lookup.prodPricePos = modals.params.prodPricePos || []
    assign.lookup.prodType = modals.params.prodType || []
  },
  methods: {
    onSubmit () {
      const { modals } = this.store
      modals.show('assign-bom-pc9-results', this.nextParams)
    }
  }
}
</script>

<style lang="scss">
.LookUpPC9CodesAttributes {
  @include assignFindPC9Codes();

  min-width: 500px;

  section.seasonSection,
  section.pc5Section {
    align-items: center;
  }

  .productAttributes {
    padding-top: 1rem;
  }

  .checks {
    display: flex;
    margin-top: 2rem;

    .prodPricePos {
      margin-right: 4rem;
    }
  }
}
</style>
