<template>
  <div class="MiniListHeader">
    <a @click="onPreviousClick">
      <ChevronLeftIcon />
      <span>Prev</span>
    </a>
    <div>
      <span>Showing </span>
      <span> {{ start }} </span>
      <span> to </span>
      <span> {{ end }} </span>
      <span> of </span>
      <span> {{ total }} </span>
    </div>
    <a @click="onNextClick">
      <span>Next</span>
      <ChevronRightIcon />
    </a>
  </div>
</template>

<script>
import ChevronLeftIcon from './icons/ChevronLeftIcon.vue'
import ChevronRightIcon from './icons/ChevronRightIcon.vue'
export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  props: {
    end: {
      type: Number,
      required: true
    },
    onNextClick: {
      type: Function,
      required: true
    },
    onPreviousClick: {
      type: Function,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
.MiniListHeader {
  display: flex;
  justify-content: space-between;
  user-select: none;

  a {
    display: flex;
    align-items: center;

    svg {
      path {
        fill: $blue;
      }
    }

    span {
      padding: 0 0.5rem;
    }
  }
}
</style>
