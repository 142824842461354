<template>
  <InlineEditor
    :edit-mode-model="this"
    :on-save="onSave"
    :value="note"
    :allow-edit="true"
  />
</template>

<script>
import InlineEditor from '../InlineEditor.vue'
export default {
  components: {
    InlineEditor
  },
  inject: [ 'store' ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    editMode: {
      get () {
        return this.store.devBoms.noteEditToggles[this.item.bompartId]
      },
      set (value) {
        return this.store.devBoms.setNoteEdit(this.item.bompartId, value)
      }
    },
    note () {
      return this.item.note || ''
    }
  },
  methods: {
    async onSave (newValue) {
      const oldValue = this.item.note
      try {
        const bomId = this.item.bompartId
        const updates = {
          note: newValue
        }

        // Make sure the note property is reactive, in case it was undefined
        this.$set(this.item, 'note', newValue)

        const response = await this.store.devBomApi.updateBom({
          bomId,
          updates
        })
        this.$set(this.item, 'note', response.note)
      } catch (error) {
        this.$set(this.item, 'note', oldValue)
      }
    }
  }
}
</script>
