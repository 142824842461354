<template>
  <ContextMenu
    v-if="allowEdit"
    class="subsectionContextMenu"
    :x-offset="-130"
    :arrow-offset="139"
  >
    <ul>
      <li>
        <a
          class="removeSubsectionLink"
          @click="onRemoveSubsectionClick(bomComponent)"
        >
          Remove Subsection
        </a>
      </li>
      <li>
        <a
          class="removeSubsectionLink"
          @click="editSubsectionClick()"
        >
          Edit Component
        </a>
      </li>
    </ul>
  </ContextMenu>
</template>

<script>
import ContextMenu from '../ContextMenu.vue'
import ConfirmRemoval from './dialogs/ConfirmRemoval.vue'
import EditSubsection from './dialogs/EditSubsection.vue'
import AlertMessage from './dialogs/AlertMessage.vue'

export default {
  components: {
    ContextMenu
  },
  inject: [ 'store' ],
  props: {
    bomComponent: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    subsectionTitle: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedComponent: null
    }
  },
  computed: {
    allowEdit () {
      const { bomEditor } = this.store
      const hasPermission = this.store.rbac.hasPermission('edit', 'lineItem', {
        sectionName: this.sectionName
      })
      // edit check for all bom
      const lockArr = this.selectedBoms.map(bom => bomEditor.checkMRPlock(bom.bompartId))
      const lockBOM = lockArr.findIndex(locked => locked === false)
      const allLocked = (lockBOM >= 0)
      return (hasPermission && allLocked && this.isPowerCore)
    },
    bomDescription () {
      const { bomEditor } = this.store
      // to get all unlocked boms's
      const unlockedBoms = this.selectedBoms.filter(bom => bomEditor.checkMRPlock(bom.bompartId) ? undefined : bom)
      if (unlockedBoms.length === 1) {
        const bom = unlockedBoms[0]
        return bom.colorwayName || bomEditor.devBomTitle
      }
      return `${unlockedBoms.length} BOMs`
    },
    selectedBoms () {
      const { bomEditor } = this.store
      const selectedBoms = this.bomComponent.lineItemDetails.reduce((acc, lineItem) => {
        lineItem.bompartInfo.forEach((bom) => {
          if (bomEditor.selectedBomIds.includes(bom.bompartId)) {
            acc.set(bom.bompartId, bom)
          }
        })
        return acc
      }, new Map())
      const AllselectedBoms = Array.from(selectedBoms.values())
      return AllselectedBoms
    },
    selectedBomIds () {
      const { bomEditor } = this.store
      // to get all unlocked boms's
      const unlockedBoms = this.selectedBoms.filter(bom => bomEditor.checkMRPlock(bom.bompartId) ? undefined : bom)
      return unlockedBoms.map((bom) => {
        return bom.bompartId
      })
    },
    selectedSectionBoms () {
      const { bomEditor } = this.store
      const selectedBoms = this.bomComponent.lineItemDetails.reduce((acc, lineItem) => {
        lineItem.bompartInfo.forEach((bom) => {
          if (bomEditor.selectedBomIds.includes(bom.bompartId)) {
            acc.set(bom.bomlinkId, bom)
          }
        })
        return acc
      }, new Map())
      const AllselectedBoms = Array.from(selectedBoms.values())
      return AllselectedBoms
    },
    selectedLinkIds () {
      const { bomEditor } = this.store
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      const unlockedBoms = this.selectedSectionBoms.filter(bom => bomEditor.checkMRPlock(bom.bompartId) ? undefined : bom)
      const powerCoreBoms = unlockedBoms.filter(bom => bomEditor.checkPowerCore(bom.bompartId) ? undefined : bom)
      if (validLineItems.includes(this.bomComponent.component) || validLineItems.includes(this.selectedComponent)) {
        return powerCoreBoms.filter(bom => bom.seasonalLookId === null)
          .map(bom => bom.bomlinkId)
      } else {
        return powerCoreBoms.map((bom) => {
          return bom.bomlinkId
        })
      }
    },
    isPowerCore () {
      const { bomEditor } = this.store
      const powerCoreBoms = this.selectedBoms.map((bom) => {
        return bomEditor.checkPowerCore(bom.bompartId)
      })
      const powerCoreStatus = powerCoreBoms.findIndex((locked) => locked === false)
      return (powerCoreStatus >= 0)
    }
  },
  methods: {
    async onRemoveSubsectionClick (subsection) {
      const { confirmationDialog } = this.store
      const result = await confirmationDialog.show({
        confirmClass: 'remove',
        confirmText: 'Remove',
        component: ConfirmRemoval,
        props: {
          target: this.subsectionTitle,
          parent: this.bomDescription
        },
        title: 'Confirm Removal'
      })

      if (result) {
        const { bomEditor, bomEditorApi, router } = this.store
        await bomEditorApi.removeSubsection({
          bomIds: this.selectedBomIds,
          description: this.subsectionTitle,
          section: this.sectionName,
          component: this.bomComponent.component,
          componentLocation: this.bomComponent.componentLocation
        })
        router.setHash({
          showLineItem: undefined
        })
        bomEditor.refresh()
      }
    },
    async editSubsectionClick () {
      const { confirmationDialog, alertDialog } = this.store
      const lineItem = {
        component: this.bomComponent.component,
        componentLocation: this.bomComponent.componentLocation
      }

      const result = await confirmationDialog.show({
        confirmText: 'Save',
        component: EditSubsection,
        props: {
          lineItem: lineItem,
          sectionName: this.sectionName
        },
        title: 'Edit Component'
      })
      this.selectedComponent = lineItem.component
      if (result && this.selectedLinkIds.length > 0) {
        const popups = {
          errorText: `There was an error updating the Subsection`
        }
        const { bomEditor, bomEditorApi, router } = this.store
        const ids = this.selectedLinkIds
        await bomEditorApi.updateComponent(
          ids,
          lineItem,
          popups
        )
        router.setHash(
          {
            showLineItem: this.selectedLinkIds[0]
          }
        )
        bomEditor.refresh()
      }
      if (result && this.selectedLinkIds.length === 0) {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    }
  }
}
</script>
