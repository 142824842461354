<template>
  <h3>
    <div class="DevBomCopiedFrom">
      <span class="title">
        {{ copiedFrom }}
      </span>
    </div>
  </h3>
</template>

<script>

export default {
  inject: [ 'store' ],
  computed: {
    bom () {
      return this.store.bomEditor.results.BOMResults[0] || {}
    },
    copiedFrom () {
      return this.bom.sourceName || ''
    }
  }
}
</script>

<style lang="scss">
.DevBomCopiedFrom {
  .title {
    color: $gray-lines;
    font-style: italic;
    font-size: 1.5rem;
  }
}
</style>
