<template>
  <div class="BomSearchComponentField">
    <label>Component</label>
    <select
      v-model="store.bomSearch.component"
    >
      <option
        :selected="null"
        :value="null"
      />
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  inject: ['store'],
  computed: {
    options () {
      const componentList = this.store.codes.components
      return componentList.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.BomSearchComponentField {
  select {
    text-transform: uppercase;
  }
}
</style>
