<template>
  <div class="PreviewComponent">
    <h4>
      <SubsectionTitle
        :component="component.component"
        :component-location="component.componentLocation"
      />
    </h4>
    <PreviewLineItem
      v-for="(lineItem, index) in component.lineItemDetails"
      :key="index"
      :line-item="lineItem"
    />
  </div>
</template>

<script>
import PreviewLineItem from './PreviewLineItem.vue'
import SubsectionTitle from '../SubsectionTitle.vue'

export default {
  components: {
    PreviewLineItem,
    SubsectionTitle
  },
  props: {
    component: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.PreviewComponent {
  background-color: $gray-component-background;
  padding: 2rem;
  margin-bottom: 2rem;

  h4 {
    margin-bottom: 1rem;
  }
}
</style>
