<template>
  <div class="ChooseEnterOrLookup">
    <div
      v-if="store.bomEditor.allSectionsEmpty"
      class="empty"
    >
      <h4>
        <BangCircleIcon />
        <span>
          No Line Items to assign
        </span>
      </h4>
      <p>
        A Development BOM must contain at least one
        Line Item before it can be assigned to a PC9.
      </p>
    </div>
    <div
      v-else
      class="choiceButtons"
    >
      <ModalLink
        class="choice enter"
        name="assign-bom-enter-pc9s"
        :params="linkParams('enter')"
      >
        <EnterValuesIcon />
        <div class="label">
          Enter PC9s
        </div>
      </ModalLink>

      <ModalLink
        class="choice lookup"
        name="assign-bom-look-up-pc9s-season"
        :params="linkParams('lookup')"
      >
        <LookUpValuesIcon />
        <div class="label">
          Look up PC9s
        </div>
      </ModalLink>
    </div>
  </div>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'
import EnterValuesIcon from '../icons/EnterValuesIcon.vue'
import LookUpValuesIcon from '../icons/LookUpValuesIcon.vue'
import ModalLink from '../ModalLink.vue'

export default {
  components: {
    BangCircleIcon,
    EnterValuesIcon,
    LookUpValuesIcon,
    ModalLink
  },
  inject: [ 'store' ],
  computed: {
    seasonId () {
      const { modals, bomSearch } = this.store
      return modals.params.seasonId || bomSearch.seasonId
    }
  },
  mounted () {
    this.store.assign.chooseSections.reset()
  },
  methods: {
    linkParams (flow) {
      return {
        bomId: this.store.modals.params.bomId,
        flow,
        seasonId: this.seasonId
      }
    }
  }
}
</script>

<style lang="scss">
.ChooseEnterOrLookup {
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 1rem;
      }
    }

    p {
      font-size: 16px;
    }
  }

  .choiceButtons {
    display: flex;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .choice {
      width: 24rem;
      height: 12rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed $gray-lines;
      border-radius: 5px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 2rem;
      }

      &:hover {
        border: 2px solid $gray-lines;
        box-shadow: 3px 3px 5px $gray-lines;
      }

      .label {
        margin-top: 3rem;
      }
    }
  }
}
</style>
