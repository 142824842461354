<template>
  <div class="CreateDevBom">
    <CreateDevBomSidebar />

    <CreateDevBomPreview v-if="showPreview" />

    <CreateDevBomCopyPC9 v-else-if="createType === 'copyPC9'" />

    <CreateDevBomFromScratch v-else-if="createType === 'fromScratch'" />
  </div>
</template>

<script>
import CreateDevBomCopyPC9 from './CreateDevBomCopyPC9.vue'
import CreateDevBomFromScratch from './CreateDevBomFromScratch.vue'
import CreateDevBomPreview from './CreateDevBomPreview.vue'
import CreateDevBomSidebar from './CreateDevBomSidebar.vue'

export default {
  components: {
    CreateDevBomCopyPC9,
    CreateDevBomFromScratch,
    CreateDevBomPreview,
    CreateDevBomSidebar
  },
  inject: [ 'store' ],
  computed: {
    createType () {
      return this.store.modals.params.createType
    },
    showPreview () {
      return !!this.store.modals.params.preview
    }
  }
}
</script>

<style lang="scss">
.CreateDevBom {
  display: flex;
  width: 90vw;
  height: 80vh;

  .CreateDevBomSidebar {
    margin-right: 4rem;
  }
}
</style>
