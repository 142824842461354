// Given a child and parent element, determine whether the child
// "is" the parent, or if it is a descendent of the parent.
//
// `excludeSelector` can be used to consider the child as
// "not a descendent", even if it is nested under the parent.
// Children of `excludeSelector` will also be excluded.
//
// This can be used to handle filtering of drag/drop events in a
// series of nested elements.
const isElementNested = (descendentEl, parentEl, excludeSelector) => {
  if (!descendentEl) {
    // No element to compare?
    return false
  }
  if (descendentEl === parentEl) {
    // It's the element itself
    return true
  } else {
    if (parentEl.contains(descendentEl)) {
      if (excludeSelector && descendentEl.matches(excludeSelector)) {
        // It's excluded
        return false
      } else if (excludeSelector && descendentEl.closest(excludeSelector)) {
        // It's a descendent of an excluded
        return false
      }
      // It's a descendent, and not excluded
      return true
    }
  }
  // It's either not an descendent, or it is in the
  // exclusions list.
  return false
}

export default isElementNested
