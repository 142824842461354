<template>
  <div class="DevBom page">
    <DevBomEditHeader />

    <BomEditor mode="devBom" />

    <AssignBomModals />
  </div>
</template>

<script>
import AssignBomModals from '../components/AssignBom/AssignBomModals.vue'
import BomEditor from '../components/BomEditor/BomEditor.vue'
import DevBomEditHeader from '../components/BomEditor/DevBomEditHeader.vue'

export default {
  components: {
    AssignBomModals,
    BomEditor,
    DevBomEditHeader
  }
}
</script>

<style lang="scss">
.DevBom.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .error {
    h4 {
      color: $red;
    }
  }
}
</style>
