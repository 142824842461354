<template>
  <div class="PC9Results">
    <div
      v-if="filtersSelected"
      class="header"
    >
      <SearchCriteriaList />
    </div>

    <div class="pc9Scroller">
      <PC9FilterList
        v-if="!isLoading"
        :options="results"
        :model="assign.searchResults"
        :is-loading="isLoading"
      />
    </div>

    <div
      v-if="assign.searchResults.showErrors"
      class="error"
    >
      Please select at least one PC9 BOM.
    </div>

    <div class="footer">
      <ModalLink
        :name="backLinkTarget"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div class="ModalButtonsWrapper">
        <SelectAllLink
          :is-deselect-mode="assign.searchResults.allSelected"
          :on-select-all="assign.searchResults.selectAll"
          :on-deselect-all="assign.searchResults.deselectAll"
        />

        <a
          class="button"
          @click="onNextClick"
        >
          Next
        </a>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="loading"
    >
      <Spinner class="stretch" />
    </div>
  </div>
</template>

<script>
import BackLink from '../BackLink.vue'
import ModalLink from '../ModalLink.vue'
import PC9FilterList from './PC9FilterList.vue'
import SearchCriteriaList from './SearchCriteriaList.vue'
import SelectAllLink from '../SelectAllLink.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    BackLink,
    ModalLink,
    PC9FilterList,
    SearchCriteriaList,
    SelectAllLink,
    Spinner
  },
  inject: [ 'store' ],
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { params } = this.store.modals
      return {
        ...params
      }
    },
    backLinkTarget () {
      const { params } = this.store.modals
      if (params.flow === 'lookup') {
        return 'assign-bom-look-up-pc9s-attributes'
      }
      return 'assign-bom-enter-pc9s'
    },
    isLoading () {
      return this.assign.searchResults.isLoading
    },
    filtersSelected () {
      return this.assign.lookup.prodPricePos.length > 0 || this.assign.lookup.prodType.length > 0
    },
    missingCodes () {
      const { assign, modals } = this.store
      const codes = modals.params.PC9Code.split(',')
      const missingCodes = codes.filter((code) => {
        if (code.match(/\d{5}-\d{4}/)) {
          return !assign.searchResults.results.find((bom) => {
            return code === bom.PC9Code
          })
        }
        return false
      })

      return missingCodes.map((code) => {
        return {
          PC9Code: code,
          disabled: true,
          isMissing: true
        }
      })
    },
    nextLinkParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        selectedCodes: assign.searchResults.selectedCodes
      }
    },
    results () {
      const { assign, modals } = this.store
      if (modals.params.flow === 'enter') {
        return [
          ...assign.searchResults.results,
          ...this.missingCodes
        ]
      }
      return assign.searchResults.results
    }
  },
  watch: {
    'store.modals.params': function () {
      this.resetParams()
    }
  },
  mounted () {
    this.resetParams()
  },
  methods: {
    onNextClick () {
      const { assign, modals } = this.store
      if (assign.searchResults.isValid) {
        modals.show('assign-bom-choose-sections', this.nextLinkParams)
      } else {
        assign.searchResults.showErrors = true
      }
    },
    resetParams () {
      const { assign, modals } = this.store
      assign.searchResults.reset({
        PC5Code: modals.params.PC5Code,
        PC9Code: modals.params.PC9Code,
        prodPricePos: modals.params.prodPricePos || [],
        prodType: modals.params.prodType || [],
        seasonId: modals.params.seasonId
      })
    }
  }
}
</script>

<style lang="scss">

.PC9Results {
  display: flex;
  flex-direction: column;
  min-width: 60rem;
  min-height: 22.5rem;
  max-height: 45rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }

  .pc9Scroller {
    flex-grow: 2;
    overflow-y: auto;
  }

  .error {
    text-align: right;
    color: $red;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .footer {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $gray-overlay;
  }
}
</style>
