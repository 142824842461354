<template>
  <div
    class="DropZone"
    :class="[mode, { isShowing }]"
  >
    <div class="background" />
    <div class="foreground">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShowing: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'listItem',
      validator (value) {
        return ['listItem', 'overlay'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss">
.DropZone {
  position: relative;
  flex-shrink: 0;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.85;
    background-color: lighten($green, 25%);
    border-radius: 4px;
  }

  .foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: $gray;
  }

  &.overlay {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    .background {
      border: 3px dashed $green;
    }

    &.isShowing {
      display: block;
    }
  }

  &.listItem {
    display: flex;
    height: 0;
    width: 100%;
    transition: height 100ms ease;
    overflow: hidden;

    &.isShowing {
      height: 6rem;

      .background {
        border: 3px dashed $green;
      }
    }
  }
}
</style>
