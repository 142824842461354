<template>
  <form
    class="LookUpPC9CodesSeason"
    @submit.prevent="onSubmit"
  >
    <section>
      <div class="gutter">
        <div class="stepNumber">
          1
        </div>
      </div>
      <div class="whatSeason">
        <h4>What season?</h4>
        <SeasonSelector
          :model="assign.chooseSeason"
        />
      </div>
    </section>

    <div
      v-if="assign.lookup.showErrors"
      class="error"
    >
      Please choose a season
    </div>

    <div class="footer">
      <ModalLink
        class="backLink"
        name="assign-bom-choose-enter-or-lookup"
        :params="backLinkParams"
      >
        <BackLink />
      </ModalLink>

      <div>
        <button
          class="button"
          type="submit"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import BackLink from '../BackLink.vue'
import ModalLink from '../ModalLink.vue'
import SeasonSelector from '../SeasonSelector.vue'

export default {
  inject: [ 'store' ],
  components: {
    BackLink,
    ModalLink,
    SeasonSelector
  },
  computed: {
    assign () {
      return this.store.assign
    },
    backLinkParams () {
      const { assign, modals } = this.store
      return {
        bomId: modals.params.bomId,
        seasonId: assign.chooseSeason.seasonId
      }
    },
    nextLinkParams () {
      const { assign, modals } = this.store
      return {
        ...modals.params,
        seasonId: assign.chooseSeason.seasonId,
        PC5Code: assign.lookup.PC5Code
      }
    }
  },
  mounted () {
    const { chooseSeason, lookup } = this.store.assign
    chooseSeason.seasonId = this.store.modals.params.seasonId
    lookup.showErrors = false
  },
  methods: {
    onSubmit () {
      const { assign, modals } = this.store
      assign.lookup.showErrors = false
      if (assign.chooseSeason.seasonId.length) {
        modals.show('assign-bom-look-up-pc9s-pc5', this.nextLinkParams)
      } else {
        assign.lookup.showErrors = true
      }
    }
  }
}
</script>

<style lang="scss">
.LookUpPC9CodesSeason {
  @include assignFindPC9Codes();
}
</style>
