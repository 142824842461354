<template>
  <div
    class="BomSearch"
    @keypress.enter="generateReport"
  >
    <form @submit="onSubmit">
      <div class="searchFields">
        <div class="row">
          <BomSearchSeasonField
            class="cell"
            :model="store.bomSearch"
          />

          <BomSearchClassField class="cell" />

          <BomSearchSubclassField class="cell" />

          <div class="cell clearAllFieldsCell">
            <a
              class="clearAllFields"
              @click="onClearAllFieldsClick"
            >
              <XIcon />
              Clear All Fields
            </a>
          </div>
        </div>

        <div class="row">
          <div class="cell">
            <label>PC9 Code</label>
            <AutoComplete
              class="PC9Code"
              type="PC9/code"
              value-key="lscoColorwayCode"
              :model="store.bomSearch"
              name="PC9Code"
              :show-label="false"
              focus
            />
          </div>

          <div class="cell">
            <label>PC9 Name</label>
            <AutoComplete
              class="PC9Name"
              type="PC9/name"
              value-key="colorwayName"
              :model="store.bomSearch"
              name="PC9Name"
              :show-label="false"
            />
          </div>

          <div class="cell">
            <label>PC5 Code</label>
            <AutoComplete
              class="PC5Code"
              type="PC5/code"
              value-key="lscoProductCode"
              :model="store.bomSearch"
              name="PC5Code"
              :show-label="false"
            />
          </div>

          <div class="cell">
            <label>PC5 Name</label>
            <AutoComplete
              class="PC5Name"
              type="PC5/name"
              value-key="productName"
              :model="store.bomSearch"
              name="PC5Name"
              :show-label="false"
            />
          </div>
        </div>

        <div class="row">
          <div class="cell">
            <label>PDS</label>
            <UserLookup
              class="PDSUser"
              :model="store.bomSearch"
              name="PDSUser"
            />
          </div>

          <div class="cell">
            <label>Merchant</label>
            <UserLookup
              class="merchUser"
              :model="store.bomSearch"
              name="merchUser"
            />
          </div>

          <div class="cell">
            <label>Designer</label>
            <UserLookup
              class="designUser"
              :model="store.bomSearch"
              name="designUser"
            />
          </div>
        </div>

        <div class="row">
          <div class="cell">
            <label>Material</label>
            <AutoComplete
              class="material"
              type="material"
              value-key="lscoMaterialCode"
              labeler="materialName"
              :model="store.bomSearch"
              name="material"
            />
          </div>

          <div class="cell">
            <label>Color</label>
            <AutoComplete
              class="color"
              type="color"
              value-key="colorId"
              labeler="lscoSearchCode"
              :model="store.bomSearch"
              name="color"
            />
          </div>

          <BomSearchComponentField class="cell" />

          <BomSearchComponentLocationField class="cell" />
        </div>

        <div class="row checkboxList">
          <CheckOptions
            class="cell"
            header="Available?"
            :options="store.codes.available"
            :model="store.bomSearch"
            name="isAvailable"
          />

          <CheckOptions
            class="cell"
            header="Carryover or New?"
            :options="store.codes.carryoverOrNew"
            :model="store.bomSearch"
            name="carryoverOrNew"
          />

          <CheckOptions
            class="cell"
            header="Product Price Positioning"
            :options="store.codes.prodPricePos"
            :model="store.bomSearch"
            name="prodPricePos"
          />

          <CheckOptions
            class="cell"
            header="Product Type"
            :options="store.codes.prodType"
            :model="store.bomSearch"
            name="prodType"
          />
        </div>

        <div class="checkbox-list">
          <CheckOptions
            class="GTMTrack"
            header="GTM Track"
            :options="store.codes.GTMTrack"
            :model="store.bomSearch"
            name="GTMTrack"
          />
        </div>

        <div class="checkbox-list">
          <CheckOptions
            class="earlyDelivery"
            header="Early Delivery"
            :options="store.codes.earlyDelivery"
            :model="store.bomSearch"
            name="earlyDelivery"
          />
        </div>
        <div class="row checkboxList">
          <CheckOptions
            class="cell"
            header="MRP Lock"
            :options="store.codes.lscoMrpLock"
            :model="store.bomSearch"
            name="lscoMrpLock"
          />
          <BomPowerCore
            class="cell"
            header="Power Core"
            :model="store.bomSearch"
          />
        </div>
      </div>
      <input
        type="submit"
        hidden="true"
      >
    </form>
  </div>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'
import BomSearchClassField from './BomSearchClassField.vue'
import BomSearchSeasonField from './BomSearchSeasonField.vue'
import BomSearchSubclassField from './BomSearchSubclassField.vue'
import BomSearchComponentField from './BomSearchComponentField.vue'
import BomSearchComponentLocationField from './BomSearchComponentLocationField.vue'
import CheckOptions from '../CheckOptions.vue'
import UserLookup from './UserLookup.vue'
import XIcon from '../icons/XIcon.vue'
import BomPowerCore from '../BomPowerCore.vue'

export default {
  components: {
    AutoComplete,
    BomSearchClassField,
    BomSearchSeasonField,
    BomSearchSubclassField,
    BomSearchComponentField,
    BomSearchComponentLocationField,
    CheckOptions,
    UserLookup,
    XIcon,
    BomPowerCore
  },
  inject: ['store'],
  mounted () {
    const { query } = this.$route
    this.store.bomSearch.setValues({ ...query })
  },
  methods: {
    onClearAllFieldsClick () {
      const { bomSearch } = this.store
      bomSearch.reset()
    },
    onSubmit (event) {
      event.preventDefault()
      this.store.bomSearch.goToResults()
    },
    generateReport (e) {
      if (this.store.bomSearch.material.length > 2) {
        e.preventDefault()
        this.store.bomSearch.generateReport()
      } else {
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss">
.BomSearch {
  @include centerColumn;

  form {
    @include cardFull;
  }

  .GTMTrack {
    padding-bottom: 3.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid $gray-lines;
  }

  .lscoMrpLock {
    padding-top: 3rem;
    border-top: 1px solid $gray-lines;
  }

  .GTMTrack,
  .earlyDelivery {
    ul {
      padding-bottom: 3.5rem;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        flex-basis: 25%;
      }
    }
  }

  .searchFields {
    $gutterSize: 2rem;

    .row {
      display: flex;
      padding-bottom: 2.4rem;
      border-bottom: 1px solid $gray-lines;
      margin-bottom: 2.5rem;

      .cell {
        width: calc(25% - #{$gutterSize});
        margin-right: $gutterSize;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .clearAllFieldsCell {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        a.clearAllFields {
          display: flex;
          align-items: center;
          color: $gray-sec;

          svg {
            margin-right: 1rem;
          }
        }
      }
    }

    h5 {
      font-size: 1.2rem;
      color: $gray-sec;
      text-transform: uppercase;
      letter-spacing: 0.02rem;
    }
  }
}
</style>
