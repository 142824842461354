<template>
  <div class="ConfirmRemoval">
    <span class="target">
      {{ target }}
    </span>

    <span>
      will be removed from
    </span>

    <span class="parent">
      {{ parent }}
    </span>.
  </div>
</template>

<script>
export default {
  props: {
    parent: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.ConfirmRemoval {
  .target,
  .parent {
    color: $blue;
    font-style: italic;
  }
}
</style>
