<template>
  <SubHeader class="EditPC9Header">
    <template v-slot:leftSide>
      <h3>{{ bompartName }}</h3>
    </template>

    <template v-slot:rightSide>
      <router-link
        class="button"
        :to="gridModeLink"
      >
        View in Grid Editor
      </router-link>

      <ModalLink
        name="review-mode"
        class="button"
      >
        Review Mode
      </ModalLink>
      <router-link
        class="button"
        :to="MRPLockView"
      >
        Change MRP Lock
      </router-link>

      <Modal
        name="review-mode"
        title="Review Mode"
      >
        <BomReview />
      </Modal>
    </template>
  </SubHeader>
</template>

<script>
import BomReview from '../BomReview/BomReview.vue'
import Modal from '../Modal.vue'
import ModalLink from '../ModalLink.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    BomReview,
    Modal,
    ModalLink,
    SubHeader
  },
  inject: [ 'store' ],
  computed: {
    bompartName () {
      const { boms } = this.store.bomEditor
      if (boms && boms.length) {
        return boms[0].bompartName
      }
      return ''
    },
    gridModeLink () {
      const query = {
        ...this.store.router.query,
        mode: 'bom'
      }

      return {
        path: '/bom-grid',
        query,
        hash: ''
      }
    },
    MRPLockView () {
      const query = {
        ...this.store.router.query,
        mode: 'bom'
      }
      return {
        path: '/edit-lock',
        query,
        hash: ''
      }
    }
  }
}
</script>
