<template>
  <div class="PC9FilterList">
    <div
      v-if="options.length"
      class="listItems"
    >
      <PC9FilterItem
        v-for="option in options"
        :key="option.PC9Code"
        :option="option"
        :model="model"
        :sections="sections"
        :show-warnings="showWarnings"
      />
    </div>
    <div v-else-if="!isLoading">
      No PC9s found.
    </div>
  </div>
</template>

<script>
import PC9FilterItem from './PC9FilterItem.vue'

export default {
  components: {
    PC9FilterItem
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    sections: {
      type: Array,
      default () {
        return []
      }
    },
    showWarnings: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.PC9FilterList {
  .listItems {
    display: grid;
    max-width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(calc(36ch + 32px), 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 8px;
  }

  .PC9FilterItem {
    label,
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
