<template>
  <div
    v-observe-visibility="{ callback: visibilityChanged, once: true }"
    class="BomReviewBom"
  >
    <div v-if="isVisible">
      <h3>{{ bom.title || bom.bompartName || 'Untitled' }}</h3>
      <BomReviewSection
        v-for="section in bom.sections"
        :key="`${bom.bompartId}__${section.sectionName}`"
        :section="section"
      />
    </div>
  </div>
</template>

<script>
import BomReviewSection from './BomReviewSection.vue'

export default {
  components: {
    BomReviewSection
  },
  inject: [ 'store' ],
  props: {
    bom: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    }
  }
}
</script>

<style lang="scss">
.BomReviewBom {
  // We need some visible content to ensure `visibilityChanged` always fires
  min-height: 1rem;

  &:not(:first-child) {
    margin-top: 4rem;
    border-top: 1px solid $gray-lines;
    padding-top: 2rem;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
  }
}
</style>
