<template>
  <div class="BomEditorListHeader">
    <h3 class="sectionName">
      {{ label }}
    </h3>

    <a
      v-if="allowEdit"
      class="action"
      @click="onFindMaterialClick"
    >
      <SearchIcon />
      Find {{ label }}
    </a>

    <a
      v-if="allowEdit"
      class="action addPlaceholder"
      @click="onAddPlaceholderClick"
    >
      <PlusIcon />
      Add {{ label }} Subsection
    </a>

    <div class="links">
      <a
        class="expandAll"
        @click="onExpandAllClick"
      >
        <PlusIcon />
        Expand All
      </a>

      <a
        class="collapseAll"
        @click="onCollapseAllClick"
      >
        <MinusIcon />
        Collapse all
      </a>
    </div>
  </div>
</template>

<script>
import AddSubsection from './dialogs/AddSubsection.vue'
import MinusIcon from '../icons/MinusIcon.vue'
import PlusIcon from '../icons/PlusIcon.vue'
import SearchIcon from '../icons/SearchIcon.vue'
import AlertMessage from './dialogs/AlertMessage.vue'
export default {
  components: {
    MinusIcon,
    PlusIcon,
    SearchIcon
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    allowEdit () {
      // to check whether boms are locked or not
      const { selectedBoms, mode } = this.store.bomEditor
      let isLocked = true
      if (mode === 'pc9Edit') {
        isLocked = selectedBoms[0].lscoMrpLock !== 'Y'
      } else if (mode === 'pc9Search') {
        isLocked = selectedBoms.find(bom => bom.lscoMrpLock !== 'Y')
      }
      return this.store.rbac.hasPermission('edit', 'lineItem', {
        sectionName: this.sectionName
      }) && isLocked
    },
    label () {
      return this.store.codes.sections[this.sectionName]
    }
  },
  methods: {
    async addPlaceholder () {
      const { bomEditor, bomEditorApi, confirmationDialog, alertDialog, router } = this.store

      const overrides = {
        quantity: bomEditor.getDefaultQuantity(this.sectionName),
        lscoRemarks: null
      }

      const result = await confirmationDialog.show({
        confirmText: 'Add Subsection',
        component: AddSubsection,
        props: {
          lineItem: overrides,
          sectionName: this.sectionName
        },
        title: 'Add Subsection'
      })

      if (result) {
        const bomIdsNotLocked = bomEditor.selectedBomIds.filter(bom => {
          return !(bomEditor.checkMRPlock(bom)) && !(bomEditor.checkPowerCore(bom))
        })
        const lineItem = {
          section: this.sectionName,
          ...overrides
        }
        const popups = {
          errorText: `There was an error adding the subsection`
        }
        const component = overrides.component
        let bomIds = []
        if (component === 'A' || component === 'primaryFinish') {
          bomIds = bomEditor.hasPrimaryFabric(bomIdsNotLocked, this.sectionName)
        } else {
          bomIds = bomIdsNotLocked
        }
        if (bomIds.length > 0) {
          const response = await bomEditorApi.addLineItem({
            bomIds,
            lineItem,
            popups
          })
          router.setHash({
            showLineItem: response[0].bomlinkId
          })
          bomEditor.refresh()
        } else if (bomIds.length === 0) {
          await alertDialog.show({
            component: AlertMessage,
            props: {
              message: `Action could not be completed. Bom is either Locked or Power Core.`
            },
            title: 'Error'
          })
        } else {
          await alertDialog.show({
            component: AlertMessage,
            props: {
              message: `Action could not be completed. Bom Already have a primary ${this.sectionName}.`
            },
            title: 'Error'
          })
        }
      }
    },
    onAddPlaceholderClick () {
      this.addPlaceholder()
    },
    onCollapseAllClick () {
      this.store.bomEditor.collapseComponents(this.sectionName)
    },
    onExpandAllClick () {
      this.store.bomEditor.expandComponents(this.sectionName)
    },
    onFindMaterialClick () {
      this.store.findMaterial.open()
    }
  }
}
</script>

<style lang="scss">
.BomEditorListHeader {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4rem;

  .sectionName {
    text-transform: capitalize;
  }

  a {
    display: flex;
    align-items: center;
    margin-left: 2rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .links {
    flex-grow: 10;
    display: flex;
    justify-content: flex-end;

    a {
      svg {
        path {
          fill: $gray-sec;
        }
      }
    }
  }

  .action {
    text-transform: capitalize;

    &.addPlaceholder {
      svg {
        path {
          fill: $gray-sec;
        }
      }
    }
  }
}
</style>
