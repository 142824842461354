<template>
  <div class="CreateDevBomSidebarPreview">
    <div v-if="allowBack">
      <a
        class="backButton"
        @click="onBackClick"
      >
        <ChevronLeftIcon />
        <span>Back</span>
      </a>
    </div>

    <h3 class="labelHeader">
      Sections To Copy
    </h3>
    <div class="selection">
      <div
        v-if="store.createDevBomPreview.allSectionsSelected === false"
        class="selectAll"
      >
        <a @click="onSelectAllClick">
          Select All
        </a>
      </div>

      <div
        v-if="store.createDevBomPreview.showClearAll === true"
        class="clearAll"
      >
        <a @click="onClearAllClick">
          Clear All
        </a>
      </div>
    </div>
    <CheckOptions
      :options="store.codes.rbacSectionOptions"
      :model="store.createDevBomPreview"
      name="sectionsToCopy"
    />

    <button
      class="button"
      @click="onCopyClick"
    >
      Copy to Development BOM
    </button>

    <Spinner
      v-if="state === 'loading'"
      class="stretch"
    />
    <div
      v-if="state === 'error'"
      class="error"
    >
      Sorry, this Development BOM could not be created. Please
      try again later, or contact technical support.
    </div>
  </div>
</template>

<script>
import ChevronLeftIcon from '../icons/ChevronLeftIcon.vue'
import CheckOptions from '../CheckOptions.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    ChevronLeftIcon,
    CheckOptions,
    Spinner
  },
  inject: [ 'store' ],
  data () {
    return {
      state: 'idle'
    }
  },
  computed: {
    allowBack () {
      return this.store.modals.params.allowBack
    }
  },
  methods: {
    async copyToDevBom () {
      try {
        this.state = 'loading'
        const response = await this.store.devBomApi.copyFrom({
          type: this.store.modals.params.createType,
          bomId: this.store.modals.params.preview,
          sections: this.store.createDevBomPreview.sectionsToCopy
        })
        const { bompartId } = response.BOMSearchMetadata.BOMResults[0]
        const query = {
          id: bompartId
        }
        this.store.router.push({
          path: '/dev-bom/fabric',
          query
        })
      } catch (error) {
        this.state = 'error'
      }
    },
    onBackClick () {
      this.state = 'idle'
      this.store.modals.updateParams({
        preview: false
      })
    },
    onCopyClick () {
      this.copyToDevBom()
    },
    onSelectAllClick () {
      this.store.createDevBomPreview.selectAllSections()
    },
    onClearAllClick () {
      this.store.createDevBomPreview.clearAllSections()
    }
  }
}
</script>

<style lang="scss">
.CreateDevBomSidebarPreview {
  .backButton {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    svg {
      margin-right: 0.75rem;
    }
  }

  .labelHeader {
    margin-bottom: 1rem;
  }

  .selection {
    display: flex;
    width: 100%;

    .clearAll {
      margin-bottom: 2rem;
    }

    .selectAll {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }

  .CheckOptions {
    margin-bottom: 4rem;

    li {
      label {
        color: $gray;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 14px;

        input[type="checkbox"] {
          margin-right: 1rem;
        }
      }
    }
  }

  .Spinner {
    margin-top: 4rem;
  }
}
</style>
