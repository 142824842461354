<template>
  <div
    class="BomReviewSidebarSection"
    :class="{ hidden: isHidden }"
    @click="onClick"
  >
    <SectionIcon :section="section.value" />
    <div class="sectionLabel">
      {{ section.label }}
    </div>
    <EyeIcon class="eye" />
  </div>
</template>

<script>
import EyeIcon from '../icons/EyeIcon.vue'
import SectionIcon from '../SectionIcon.vue'

export default {
  components: {
    EyeIcon,
    SectionIcon
  },
  inject: [ 'store' ],
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    iconSrc () {
      return `/images/ico-${this.section.value}.svg`
    },
    isHidden () {
      return !this.store.bomReview.sectionToggles[this.section.value]
    }
  },
  methods: {
    onClick () {
      this.store.bomReview.toggleSection(this.section.value)
    }
  }
}
</script>

<style lang="scss">
.BomReviewSidebarSection {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;

  svg {
    margin-right: 1rem;
  }

  .sectionLabel {
    flex-grow: 2;
  }

  .eye {
    display: none;
  }

  &:hover {
    background-color: $gray-light;

    .eye {
      display: block;
    }
  }

  &.hidden {
    color: $gray-tertiary;

    .eye {
      display: block;
    }

    svg {
      path {
        fill: $gray-tertiary;
      }
    }
  }
}
</style>
