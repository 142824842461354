<template>
  <div>
    <BlueCircle v-if="seasonalLookOnly" />
    <PurpleCircle v-if="BFFOnly || BFFOnlyNonValidLineItems" />
    <PurpleAndBlueCircle v-if="seasonalLookAndBFF" />
    <NoColorCircle v-if="noSeasonalLookNoBFF" />
  </div>
</template>

<script>
import BlueCircle from '../svg/BlueCircle.vue'
import PurpleCircle from '../svg/PurpleCircle.vue'
import PurpleAndBlueCircle from '../svg/PurpleAndBlueCircle.vue'
import NoColorCircle from '../svg/NoColorCircle.vue'

export default {
  components: {
    BlueCircle, PurpleCircle, PurpleAndBlueCircle, NoColorCircle
  },
  props: {
    seasonalLookId: {
      type: String,
      default: ''
    },
    prodType: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  computed: {
    isBFF () {
      return this.prodType === 'Z'
    },
    isSeasonalLook () {
      if (this.seasonalLookId !== null) {
        return this.seasonalLookId.length
      } else return false
    },
    seasonalLookOnly () {
      return this.isSeasonalLook && !this.isBFF && this.validLineItem
    },
    seasonalLookAndBFF () {
      return this.isSeasonalLook && this.isBFF && this.validLineItem
    },
    BFFOnly () {
      return !this.isSeasonalLook && this.isBFF
    },
    BFFOnlyNonValidLineItems () {
      return !this.validLineItem && this.isBFF
    },
    noSeasonalLookNoBFF () {
      return !this.isSeasonalLook && !this.isBFF
    },
    validLineItem () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      return validLineItems.includes(this.component)
    }
  }
}
</script>
