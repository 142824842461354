<template>
  <InlineEditor
    class="Quantity"
    label="Quantity:"
    :edit-mode-model="this"
    :on-save="onSave"
    :value="value"
    :allow-edit="allowEdit"
  />
</template>

<script>

import InlineEditor from '../InlineEditor.vue'
export default {
  components: {
    InlineEditor
  },
  inject: [ 'store' ],
  props: {
    allowEdit: {
      type: Boolean,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editMode: false
    }
  },
  computed: {
    selectedBomLinkIds () {
      return this.unlockedSelectedBom.map((bom) => {
        return bom.bomlinkId
      })
    },
    selectedBoms () {
      const { bomEditor } = this.store
      const filteredBom = this.lineItem.bompartInfo.filter((bom) => {
        return this.store.bomEditor.filters.includes(bom.bompartId)
      })
      return filteredBom.filter(bom => {
        return bomEditor.checkMRPlock(bom.bompartId)
          ? undefined
          : bom.bompartId
      })
    },
    unlockedSelectedBom () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      if (validLineItems.includes(this.component)) {
        return this.selectedBoms.filter(bom => bom.seasonalLookId === null)
      } else {
        return this.selectedBoms
      }
    },
    value () {
      return (this.lineItem.quantity || 0).toString()
    }
  },
  methods: {
    async onSave (newValue) {
      const oldValue = this.lineItem.quantity
      try {
        const popups = {
          errorText: 'There was an error updating Quantity'
        }

        const lineItem = {
          quantity: newValue
        }

        const lineItemIds = this.selectedBomLinkIds
        await this.store.bomEditorApi.updateLineItem({
          lineItemIds,
          lineItem,
          popups,
          // Note: these two params are only used by the Dev BOM API.
          bompartId: this.selectedBoms[0].bompartId,
          section: this.lineItem.section
        })
        this.lineItem.quantity = newValue
      } catch (error) {
        this.lineItem.quantity = oldValue
      }
      this.store.bomEditor.refresh()
    }
  }
}
</script>

<style lang="scss">
.Quantity {
  &.InlineEditor {
    max-width: 12rem;
  }
}
</style>
