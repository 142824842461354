<template>
  <ul
    class="CreateDevBomSidebarSubnav"
  >
    <li>
      <ModalLink
        name="create-dev-bom"
        :params="{ createType: 'copyPC9' }"
        :class="getClass('copyPC9')"
      >
        <CopyIcon />
        Copy PC9 BOM
      </ModalLink>
    </li>
    <li>
      <ModalLink
        name="create-dev-bom"
        :params="{ createType: 'fromScratch' }"
        :class="getClass('fromScratch')"
      >
        <PlusInCircleIcon />
        Create from Scratch
      </ModalLink>
    </li>
  </ul>
</template>

<script>
import CopyIcon from '../icons/CopyIcon.vue'
import ModalLink from '../ModalLink.vue'
import PlusInCircleIcon from '../icons/PlusInCircleIcon.vue'

export default {
  components: {
    CopyIcon,
    ModalLink,
    PlusInCircleIcon
  },
  inject: ['store'],
  methods: {
    onClick (name) {
      this.store.router.setHash({ createType: name })
    },
    getClass (name) {
      if (this.store.router.hash.createType === name) {
        return 'active'
      }
    }
  }
}
</script>

<style lang="scss">
.CreateDevBomSidebarSubnav {
  li a {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    color: $gray;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 0;
      background: $gray;
      right: -1px;
      top: 50%;
      opacity: 0;
      transition: all 0.25s;
    }

    &:hover {
      opacity: 0.8;

      &::after {
        top: 0;
        height: 100%;
        opacity: 0.5;
      }
    }

    &.active {
      &::after {
        height: 100%;
        opacity: 1;
        top: 0;
      }
    }

    svg {
      margin-right: 1.5rem;
    }
  }
}
</style>
