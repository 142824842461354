<template>
  <ul class="SearchCriteriaList">
    <SearchCriteriaItem
      v-for="item in items"
      :key="`${item.key}__${item.value}`"
      :item="item"
      @removeItem="removeItem"
    />
  </ul>
</template>

<script>
import SearchCriteriaItem from './SearchCriteriaItem.vue'

export default {
  inject: [ 'store' ],
  components: {
    SearchCriteriaItem
  },
  computed: {
    items () {
      const { params } = this.store.modals
      const { codes } = this.store
      const items = []

      if (params.PC5Code && params.PC5Code.length) {
        items.push({
          key: 'PC5Code',
          value: params.PC5Code,
          label: params.PC5Code
        })
      }

      this.prodPricePos.map((prodPricePos) => {
        const found = codes.prodPricePos.find((option) => {
          return option.value === prodPricePos
        })
        items.push({
          key: 'prodPricePos',
          value: found.value,
          label: found.label
        })
      })

      this.prodType.map((prodType) => {
        const found = codes.prodType.find((option) => {
          return option.value === prodType
        })
        items.push({
          key: 'prodType',
          value: found.value,
          label: found.label
        })
      })

      return items
    },
    prodPricePos () {
      return this.store.modals.params.prodPricePos || []
    },
    prodType () {
      return this.store.modals.params.prodType || []
    }
  },
  methods: {
    removeItem (item) {
      if (item.key === 'PC5Code') {
        this.removePC5Code()
      } else if (item.key === 'prodPricePos') {
        this.removeProdPricePos(item.value)
      } else if (item.key === 'prodType') {
        this.removeProdType(item.value)
      }
    },
    removePC5Code () {
      this.store.modals.updateParams({
        PC5Code: ''
      }, {
        useReplace: true
      })
    },
    removeProdPricePos (value) {
      const prodPricePos = this.prodPricePos.filter((item) => {
        return item !== value
      })
      this.store.modals.updateParams({
        prodPricePos
      }, {
        useReplace: true
      })
    },
    removeProdType (value) {
      const prodType = this.prodType.filter((item) => {
        return item !== value
      })
      this.store.modals.updateParams({
        prodType
      }, {
        useReplace: true
      })
    }
  }
}
</script>

<style lang="scss">
.SearchCriteriaList {
  display: flex;
  flex-wrap: wrap;
}
</style>
