<template>
  <div class="AddSubsection">
    <div class="subsectionFields">
      <div>
        <label>Component</label>
        <ComponentDropdown
          :section-name="sectionName"
          :model="lineItem"
          name="component"
        />
      </div>

      <div>
        <label>Component Location</label>
        <ComponentLocationDropdown
          :section-name="sectionName"
          :model="lineItem"
          name="componentLocation"
        />
      </div>
    </div>
    <div
      v-if="showError"
      class="error"
    >
      Component is required
    </div>

    <AddMaterial
      ref="addMaterial"
      :line-item="lineItem"
    />
  </div>
</template>

<script>
import AddMaterial from './AddMaterial.vue'
import ComponentDropdown from '../ComponentDropdown.vue'
import ComponentLocationDropdown from '../ComponentLocationDropdown.vue'

export default {
  components: {
    AddMaterial,
    ComponentDropdown,
    ComponentLocationDropdown
  },
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showError: false
    }
  },
  methods: {
    isValidSubsection () {
      return (
        this.lineItem.component &&
        this.lineItem.component.length
      )
    },
    validate () {
      const subsectionValid = this.isValidSubsection()
      const quantityValid = this.$refs.addMaterial.validate()
      this.showError = !subsectionValid
      return subsectionValid && quantityValid
    }
  }
}
</script>

<style lang="scss">
.AddSubsection {
  padding: 1rem 4rem;

  .subsectionFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  .error {
    color: $red;
  }

  .AddMaterial {
    padding: 0;
    margin-top: 3rem;

    form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 3rem;
      align-items: start;

      .remarksField {
        grid-column-end: span 3;
      }
    }
  }
}
</style>
