<template>
  <SubHeader class="SearchResultsHeader">
    <template v-slot:leftSide>
      <h3>Search Results for {{ season }}</h3>

      <BomResultsMenu :model="store.bomEditor" />

      <router-link
        :to="searchLinkUrl"
        class="edit-search"
      >
        <EditIcon />
        Edit Search
      </router-link>
    </template>

    <template v-slot:rightSide>
      <router-link
        class="button"
        :to="gridModeLink"
      >
        View in Grid Editor
      </router-link>
      <ModalLink
        name="review-mode"
        class="button"
      >
        Review Mode
      </ModalLink>

      <router-link
        class="button"
        :to="carryoverBOM"
      >
        Carryover BOM
      </router-link>

      <router-link
        class="button"
        :to="MRPLockView"
      >
        Change MRP Lock
      </router-link>
      <Modal
        name="review-mode"
        title="Review Mode"
      >
        <BomReview />
      </Modal>
    </template>
  </SubHeader>
</template>

<script>
import BomResultsMenu from '../BomResultsMenu.vue'
import BomReview from '../BomReview/BomReview.vue'
import EditIcon from '../icons/EditIcon.vue'
import Modal from '../Modal.vue'
import ModalLink from '../ModalLink.vue'
import SubHeader from '../SubHeader.vue'

export default {
  components: {
    BomResultsMenu,
    BomReview,
    EditIcon,
    Modal,
    ModalLink,
    SubHeader
  },
  inject: ['store'],
  computed: {
    gridModeLink () {
      const query = {
        ...this.store.router.query,
        mode: 'search'
      }

      return {
        path: '/bom-grid',
        query,
        hash: this.store.router.hashString
      }
    },
    MRPLockView () {
      const query = {
        ...this.store.router.query,
        mode: 'search'
      }
      return {
        path: '/edit-lock',
        query,
        hash: this.store.router.hashString
      }
    },
    carryoverBOM () {
      const query = {
        ...this.store.router.query,
        mode: 'search'
      }
      return {
        path: '/carry-over',
        query,
        hash: this.store.router.hashString
      }
    },
    searchLinkUrl () {
      const { query } = this.store.router
      return {
        path: '/search',
        query
      }
    },
    season () {
      const { seasonId } = this.store.router.query
      const season = this.store.seasons.find(seasonId)
      return season.seasonName
    }
  }
}
</script>
