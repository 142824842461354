<template>
  <drop
    class="LineItem"
    :class="{ selected: isShowLineItem }"
    @dragover="onDragover"
    @dragleave="onDragleave"
    @drop="onDrop"
  >
    <div class="lineItemCardTop">
      <div
        v-if="noMaterial"
        class="noMaterial"
      >
        <BangCircleIcon />
        <p>No material added</p>
        <LineItemRemarks
          class="emptyItemRemarks"
          :line-item="lineItem"
          :allow-edit="allowEdit"
        />
      </div>
      <div
        v-else
        class="gridWrapper"
      >
        <div class="lineItemGrid">
          <div
            class="cell materialDescription"
            :class="{ empty: materialDescriptionEmpty }"
          >
            {{ materialDescription }}
          </div>
          <div
            class="cell supplierDescription"
            :class="{ empty: supplierDescriptionEmpty }"
          >
            {{ supplierDescription }}
          </div>
          <div
            class="cell colorDescription"
            :class="{ empty: colorDescriptionEmpty }"
          >
            {{ colorDescription }}
          </div>
          <div class="cell quantity">
            <Quantity
              :line-item="lineItem"
              :allow-edit="allowEdit"
            />
          </div>
          <div class="cell remarks">
            <LineItemRemarks
              :line-item="lineItem"
              :allow-edit="allowEdit"
            />
          </div>
        </div>
      </div>
      <LineItemContextMenu
        v-if="allowEdit"
        :show-material-options="!noMaterial"
        :line-item="lineItem"
        :subsection-title="subsectionTitle"
        :component="component"
      />
    </div>

    <DropZone
      :is-showing="isDropTarget"
      mode="overlay"
    >
      <span v-if="noMaterial">
        Add Material
      </span>
      <span v-else>
        Replace Material
      </span>
    </DropZone>

    <MaterialBomList
      v-if="showBoms"
      :line-item="lineItem"
      :section-name="sectionName"
      :component="component"
    />
    <div class="pc9count">
      {{ sortedBOMS.length }} PC9 <span v-if="sortedBOMS.length > 1">s</span>
    </div>
  </drop>
</template>

<script>
import AddMaterial from './dialogs/AddMaterial.vue'
import BangCircleIcon from '../icons/BangCircleIcon.vue'
import LineItemContextMenu from './LineItemContextMenu.vue'
import ConfirmReplacement from './dialogs/ConfirmReplacement.vue'
import AlertMessage from './dialogs/AlertMessage.vue'
import DropZone from '../DropZone.vue'
import isElementNested from '../../util/isElementNested.js'
import LineItemRemarks from './LineItemRemarks.vue'
import MaterialBomList from './MaterialBomList.vue'
import Quantity from './Quantity.vue'

export default {
  components: {
    BangCircleIcon,
    LineItemContextMenu,
    DropZone,
    LineItemRemarks,
    MaterialBomList,
    Quantity
  },
  props: {
    lineItem: {
      type: Object,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    },
    subsectionTitle: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isDropTarget: false
    }
  },
  inject: ['store'],
  computed: {
    allowEdit () {
      // BOM's which are unlocked are editable
      const { bomEditor } = this.store
      const lockValues = this.sortedBOMS.map((elem) => {
        return bomEditor.checkMRPlock(elem.bompartId)
      })
      const lockStatus = lockValues.findIndex((elem) => elem === false)
      const checkMRPlock = !(lockStatus >= 0)
      const hasPermission = this.store.rbac.hasPermission('edit', 'lineItem', {
        sectionName: this.sectionName
      })
      return hasPermission && !checkMRPlock && !this.allSeasonalLooks && !this.isPowerCore
    },
    bomDescription () {
      if (this.selectedUnlockedBomLinkIds.length === 1) {
        const bom = this.selectedUnlockedBomLinkIds[0]
        if (bom.colorwayName && bom.colorwayName.length) {
          return bom.colorwayName
        }
        return this.store.bomEditor.devBomTitle
      }
      return `${this.selectedUnlockedBomLinkIds.length} BOMs`
    },
    colorDescription () {
      return this.lineItem.colorDescription || 'No Color'
    },
    colorDescriptionEmpty () {
      return !this.lineItem.colorDescription
    },
    isShowLineItem () {
      const bomlinkId = this.store.router.hash.showLineItem
      return this.lineItem.bompartInfo.find((bomPart) => {
        return bomlinkId === bomPart.bomlinkId
      })
    },
    materialDescription () {
      return this.lineItem.materialDescription || 'No Material'
    },
    materialDescriptionEmpty () {
      return !this.lineItem.materialDescription
    },
    noMaterial () {
      return (
        this.colorDescriptionEmpty &&
        this.materialDescriptionEmpty &&
        this.supplierDescriptionEmpty
      )
    },
    selectedBomLinkIds () {
      return this.selectedUnlockedBomLinkIds.map((bom) => {
        return bom.bomlinkId
      })
    },
    selectedUnlockedBomLinkIds () {
      const { bomEditor } = this.store
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      return this.selectedBoms.filter(bom =>
        validLineItems.includes(this.component)
          ? bomEditor.checkMRPlock(bom.bompartId) === false && !(bom.seasonalLookId !== null) && bomEditor.checkPowerCore(bom.bompartId) === false
          : bomEditor.checkMRPlock(bom.bompartId) === false && bomEditor.checkPowerCore(bom.bompartId) === false)
    },
    selectedBoms () {
      const { bomEditor } = this.store
      const selectedBOMS = this.lineItem.bompartInfo.filter((bom) => {
        return bomEditor.filters.includes(bom.bompartId)
      })
      return selectedBOMS
    },
    sortedBOMS () {
      return [...this.selectedBoms].sort((lhs, rhs) => {
        return lhs.colorwayName.localeCompare(rhs.colorwayName)
      })
    },
    showBoms () {
      return this.store.bomEditor.isPC9Search
    },
    supplierDescription () {
      return this.lineItem.supplierDescription || 'No Supplier'
    },
    supplierDescriptionEmpty () {
      return !this.lineItem.materialDescription
    },
    allSeasonalLooks () {
      const validLineItems = ['A', 'CrossSource', 'finishCrossSource', 'primaryFinish']
      const allSeasonalLooks = this.sortedBOMS.map((bom) => {
        if (validLineItems.includes(this.component)) {
          return bom.seasonalLookId !== null
        } else {
          return false
        }
      })
      const allSeasonal = allSeasonalLooks.findIndex(flag => flag === false)
      return !(allSeasonal >= 0)
    },
    isPowerCore () {
      const { bomEditor } = this.store
      const powerCoreBoms = this.sortedBOMS.map((elem) => {
        return bomEditor.checkPowerCore(elem.bompartId)
      })
      const powerCoreStatus = powerCoreBoms.findIndex((elem) => elem === false)
      return !(powerCoreStatus >= 0)
    }
  },
  mounted () {
    if (this.isShowLineItem) {
      this.scrollIntoView()
    }
  },
  updated () {
    if (this.isShowLineItem) {
      this.scrollIntoView()
    }
  },
  methods: {
    async addMaterial (transferData) {
      const { alertDialog } = this.store
      if (this.selectedBomLinkIds.length > 0) {
        const overrides = {
          quantity: this.lineItem.quantity,
          lscoRemarks: this.lineItem.lscoRemarks
        }
        const result = await this.store.confirmationDialog.show({
          confirmText: 'Add Material',
          component: AddMaterial,
          props: {
            lineItem: overrides
          },
          title: 'Add Material'
        })

        if (result) {
          this.updateMaterial(transferData, overrides)
        }
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    },
    isValidTarget (element) {
      return isElementNested(element, this.$el, '.MaterialBomItem')
    },
    onDragover (transferData = {}, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)

      if (
        isValidTransferData &&
        isValidTarget &&
        transferData.material &&
        transferData.supplier
      ) {
        this.isDropTarget = true
      }
    },
    onDragleave (transferData = {}, event) {
      const isValidTarget = this.isValidTarget(event.relatedTarget)
      if (event.relatedTarget === null || !isValidTarget) {
        this.isDropTarget = false
      }
    },
    onDrop (transferData, event) {
      const isValidTransferData = Object.keys(transferData).length
      const isValidTarget = this.isValidTarget(event.target)
      if (
        isValidTransferData &&
        isValidTarget &&
        transferData.material &&
        transferData.supplier
      ) {
        if (this.noMaterial) {
          this.addMaterial(transferData)
        } else {
          this.replaceMaterial(transferData)
        }
        this.isDropTarget = false
      }
    },
    async replaceMaterial (transferData) {
      const { alertDialog } = this.store
      if (this.selectedBomLinkIds.length > 0) {
        const newMaterial = transferData.materialName
        const oldMaterial = this.lineItem.materialDescription
        const result = await this.store.confirmationDialog.show({
          confirmText: 'Replace',
          component: ConfirmReplacement,
          props: {
            newMaterial,
            oldMaterial,
            parent: this.bomDescription
          },
          title: 'Confirm Replacement'
        })
        if (result) {
          this.updateMaterial(transferData)
        }
      } else if (this.isPowerCore) {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom is locked because the PC9 is Power Core.'
          },
          title: 'Error'
        })
      } else {
        await alertDialog.show({
          component: AlertMessage,
          props: {
            message: 'Action could not be completed. Bom locked.'
          },
          title: 'Error'
        })
      }
    },
    scrollIntoView () {
      this.$el.scrollIntoView()
      // Wait until the "selected" animation plays, and then
      // clear the `showLineItem` value
      setTimeout(() => {
        this.store.router.setHash(
          {
            showLineItem: undefined
          },
          {
            useReplace: true
          }
        )
      }, 2500)
    },
    async updateMaterial (transferData, overrides = {}) {
      const { bomEditor, bomEditorApi, router } = this.store

      const lineItem = {
        materialCode: transferData.material,
        supplierCode: transferData.supplier,
        colorId: transferData.color,
        ...overrides
      }

      const popups = {
        errorText: `There was an error adding ${transferData.materialName}`
      }

      await bomEditorApi.updateLineItem({
        lineItemIds: this.selectedBomLinkIds,
        lineItem,
        popups,
        // These params are only used by the Dev BOM API
        bompartId: this.selectedBoms[0].bompartId,
        section: this.lineItem.section
      })

      router.setHash(
        {
          showLineItem: this.selectedBomLinkIds[0]
        },
        {
          useReplace: true
        }
      )
      bomEditor.refresh()
    }
  }
}
</script>

<style lang="scss">
.LineItem {
  @include card;

  transition: box-shadow 500ms ease, transform 500ms ease, border 500ms ease;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  .emptyItemRemarks {
    color: black;
    margin-left: 50px;
  }

  &.selected {
    animation: SelectAndFade 2s none;
  }

  & > .DropZone.overlay > .foreground {
    align-items: flex-start;
    margin-top: 3.5rem;
    height: calc(100% - 3.5rem);
  }

  .lineItemCardTop {
    display: flex;

    .noMaterial {
      display: flex;
      flex-grow: 2;
      justify-content: center;
      align-items: center;
      color: $red;

      p {
        margin-left: 1rem;
      }
    }

    .gridWrapper {
      flex-grow: 2;
      display: block;
    }

    .lineItemGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-top: 0.5rem;
      grid-row-gap: 0.5rem;
      grid-column-gap: 2rem;
      align-items: center;

      .cell {
        height: 24px;
        display: flex;
        align-items: center;

        &.materialDescription,
        &.supplierDescription,
        &.remarks {
          grid-column: span 2;
          align-self: stretch;

          .LineItemRemarks {
            width: 100%;
          }
        }

        &.quantity {
          align-self: stretch;

          .Quantity {
            width: 100%;
          }
        }
      }

      .empty {
        color: $gray-sec;
      }
    }

    .LineItemContextMenu {
      flex-basis: 40px;
    }
  }

  .pc9count {
    font-size: 12px;
    font-weight: bolder;
    color: $gray-inactive;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
</style>
