<template>
  <select
    v-model="value"
    class="ComponentLocationDropdown"
  >
    <option
      v-for="location in locationOptions"
      :key="location.value"
      :value="location.value"
    >
      {{ location.label }}
    </option>
  </select>
</template>

<script>
export default {
  inject: [ 'store' ],
  props: {
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    locationOptions () {
      return [{
        value: null,
        label: ''
      },
      ...this.store.subsections[this.sectionName].locations
      ]
    },
    value: {
      get () {
        return this.model[this.name]
      },
      set (value = '') {
        this.model[this.name] = value
      }
    }
  }
}
</script>

<style lang="scss">
.ComponentLocationDropdown {
  text-transform: capitalize;
}
</style>
