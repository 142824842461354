<template>
  <div class="ConfirmReplacement">
    <span class="oldMaterial">
      {{ oldMaterial }}
    </span>

    <span> will be replaced with </span>

    <span class="newMaterial">
      {{ newMaterial }}
    </span>

    <span> for </span>

    <span class="parent">
      {{ parent }}
    </span>.
  </div>
</template>

<script>
export default {
  props: {
    newMaterial: {
      type: String,
      required: true
    },
    oldMaterial: {
      type: String,
      required: true
    },
    parent: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.ConfirmReplacement {
  .newMaterial,
  .oldMaterial,
  .parent {
    color: $blue;
    font-style: italic;
  }
}
</style>
