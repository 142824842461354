<template>
  <div class="CreateDevBomSidebar">
    <CreateDevBomSidebarSubnav v-if="showSubnav" />
    <CreateDevBomSidebarPreview v-if="showPreview" />
  </div>
</template>

<script>
import CreateDevBomSidebarPreview from './CreateDevBomSidebarPreview.vue'
import CreateDevBomSidebarSubnav from './CreateDevBomSidebarSubnav.vue'

export default {
  components: {
    CreateDevBomSidebarPreview,
    CreateDevBomSidebarSubnav
  },
  inject: ['store'],
  computed: {
    showPreview () {
      return this.store.modals.params.preview
    },
    showSubnav () {
      return !this.store.modals.params.preview
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.CreateDevBomSidebar {
  width: 288px;
  border-right: 1px solid $gray-lines;
}

</style>
