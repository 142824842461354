<template>
  <section class="FindMaterialChooseCodePane">
    <form @submit.prevent="onSubmit">
      <label>
        <span>Material</span>
      </label>
      <AutoComplete
        ref="materialCode"
        type="material"
        value-key="lscoMaterialCode"
        labeler="materialName"
        :model="store.findMaterial"
        name="material"
        focus
        @resultSelected="onSubmit"
      />
      <div class="controls">
        <input
          class="button"
          type="submit"
          :disabled="isNextDisabled"
          value="Next"
        >

        <a
          v-if="showFabricFields || showSundriesFields"
          @click="onAdvancedSearchClick"
        >
          <span v-if="!showAdvancedSearch">Show </span>
          <span v-if="showAdvancedSearch">Hide </span>
          <span> Advanced Search</span>
        </a>
      </div>
    </form>

    <div
      v-if="showAdvancedSearch"
      class="advancedSearch"
    >
      <hr>
      <FindMaterialFabricSearch v-if="showFabricFields" />
      <FindMaterialSundriesSearch v-if="showSundriesFields" />
      <ChooseCodeResultsList />
    </div>
  </section>
</template>

<script>
import AutoComplete from '../AutoComplete.vue'
import ChooseCodeResultsList from './ChooseCodeResultsList.vue'
import FindMaterialFabricSearch from './FindMaterialFabricSearch.vue'
import FindMaterialSundriesSearch from './FindMaterialSundriesSearch.vue'

export default {
  components: {
    AutoComplete,
    ChooseCodeResultsList,
    FindMaterialFabricSearch,
    FindMaterialSundriesSearch
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    isNextDisabled () {
      return this.store.findMaterial.material.length <= 0
    },
    showAdvancedSearch () {
      return this.store.chooseMaterialCode.showAdvancedSearch
    },
    showFabricFields () {
      return this.sectionName === 'fabric'
    },
    showSundriesFields () {
      return (
        this.sectionName !== 'fabric' &&
        this.sectionName !== 'finish'
      )
    }
  },
  watch: {
    'store.findMaterial.pane': function (newValue, oldValue) {
      if (newValue === 'materialCode') {
        this.$refs.materialCode.setFocus()
        this.$refs.materialCode.selectText()
      }
    }
  },
  methods: {
    focusMaterialCode () {
      this.$refs.materialCode.setFocus()
      setTimeout(() => {
        this.$refs.materialCode.selectAll()
      }, 10)
    },
    onAdvancedSearchClick () {
      const { chooseMaterialCode } = this.store
      chooseMaterialCode.showAdvancedSearch = !chooseMaterialCode.showAdvancedSearch
    },
    onSubmit () {
      this.store.findMaterial.nextPane()
      this.$refs.materialCode.blur()
    }
  }
}
</script>

<style lang="scss">
.FindMaterialChooseCodePane {
  .controls {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    input[type="submit"] {
      cursor: pointer;
    }
  }

  .advancedSearch {
    margin-top: 2rem;

    hr {
      margin-bottom: 2rem;
    }
  }

  .ChooseCodeResultsList {
    margin-top: 1rem;
  }
}
</style>
