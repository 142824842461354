<template>
  <div
    class="Collapsable"
    :class="isVisible"
  >
    <div class="collapsableHeader">
      <div class="collapsableTitle">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <CollapsableButton
        :model="model"
        :model-key="modelKey"
      />
    </div>

    <div class="collapsableContent">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
import CollapsableButton from './CollapsableButton.vue'

export default {
  components: {
    CollapsableButton
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    modelKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    isVisible () {
      if (this.model.includes(this.modelKey)) {
        return 'collapsableVisible'
      }
      return 'collapsableHidden'
    }
  }
}
</script>

<style lang="scss">
.Collapsable {
  padding-bottom: 1.6rem;

  .collapsableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem 0;

    .collapsableTitle {
      font-size: 1.7rem;
      font-family: $ff-sec;
      color: $gray-sec;
      user-select: none;
    }
  }

  &.collapsableHidden {
    .collapsableContent {
      display: none;
    }

    padding-bottom: 0;
  }
}
</style>
