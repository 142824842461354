<template>
  <div class="BomEditor">
    <section v-if="!isEmpty && !isError">
      <Sidebar :section-name="sectionName" />

      <div class="mainPanel">
        <div
          class="bomLists"
          :class="{ fade: !isReady }"
        >
          <BomEditorListHeader :section-name="sectionName" />
          <BomEditorList :section-name="sectionName" />
        </div>
        <Spinner
          v-if="!isReady"
          class="spinner stretch"
        />
      </div>
    </section>
    <section v-else-if="isEmpty">
      <div>
        <h4>
          No results found.
        </h4>

        <h5>
          Would you like to <router-link :to="editLinkUrl">
            edit your search
          </router-link>?
        </h5>
      </div>
    </section>
    <section v-else-if="isError">
      <div>
        <h4>
          We're sorry. This search didn't work properly.
        </h4>

        <h5>
          Would you like to <router-link :to="editLinkUrl">
            edit your search
          </router-link>?
        </h5>
      </div>
    </section>

    <Modal
      name="delete-devbom"
      title="Confirm Removal"
    >
      <DevBomConfirmRemoval />
    </Modal>
  </div>
</template>

<script>
import BomEditorList from './BomEditorList.vue'
import BomEditorListHeader from './BomEditorListHeader.vue'
import DevBomConfirmRemoval from '../DevBomLibrary/DevBomConfirmRemoval.vue'
import Modal from '../Modal.vue'
import Sidebar from './Sidebar.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    BomEditorList,
    BomEditorListHeader,
    DevBomConfirmRemoval,
    Modal,
    Sidebar,
    Spinner
  },
  inject: ['store'],
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  computed: {
    sectionName () {
      this.setSectionName()
      return this.$route.params.currentSection
    },
    editLinkUrl () {
      return {
        path: '/search',
        query: this.$route.query
      }
    },
    isError () {
      return this.store.bomEditor.isError
    },
    isEmpty () {
      return this.store.bomEditor.isEmpty
    },
    isReady () {
      return this.store.bomEditor.isReady
    }
  },
  mounted () {
    // If we try to get the mode based on the URL, any URL changes
    // will force re-fetching of the results, so this is a compromise.
    this.store.bomEditor.mode = this.mode
  },
  methods: {
    closeMaterialSidebar () {
      this.store.findMaterial.close()
    },
    backToMaterialSearch () {
      this.store.findMaterial.previousPane()
    },
    setSectionName () {
      this.store.findMaterial.sectionName = this.$route.params.currentSection
    }
  }
}
</script>

<style lang="scss">
.BomEditor {
  @include centerColumn;

  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mainPanel {
    display: flex;
    width: 100%;
    height: 100%;

    .bomLists {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .spinner {
      position: absolute;
      width: 80%;
      height: 80%;
      pointer-events: none;
    }

    .fade {
      opacity: 0.5;
    }
  }

  .Scroller {
    padding-top: 0;
  }

  & > section {
    display: flex;
    min-height: calc(100vh - 20rem);
    margin: 3.8rem auto 0;
    position: relative;
    width: 100%;
  }
}
</style>
