<template>
  <div class="FindMaterialColorSupplierPane">
    <div>
      <h3>Find {{ sectionLabel }}</h3>
      <div class="criteria">
        <span v-if="store.findMaterial.isCurrentMaterialNameReady">
          {{ store.findMaterial.currentMaterialName }}
        </span>
        <span v-else>
          {{ store.findMaterial.material }}…
        </span>
      </div>
    </div>

    <div
      v-if="store.findMaterial.isSupplierOptionsReady"
      class="supplierColors"
    >
      <MSCCardList />
    </div>
    <div v-else>
      <Spinner class="stretch" />
    </div>
  </div>
</template>

<script>
import MSCCardList from './MSCCardList.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    MSCCardList,
    Spinner
  },
  inject: [ 'store' ],
  props: {
    sectionName: {
      type: String,
      required: true
    }
  },
  computed: {
    color: {
      get () {
        return this.store.findMaterial.color
      },
      set (value) {
        this.store.findMaterial.color = value
      }
    },
    colorOptions () {
      return this.store.findMaterial.colorOptions
    },
    isFabric () {
      return this.sectionName === 'fabric'
    },
    sectionLabel () {
      return this.store.codes.sections[this.sectionName]
    }
  },
  methods: {
    onSearchClick () {
      this.store.findMaterial.nextPane()
    }
  }
}
</script>

<style lang="scss">
.FindMaterialColorSupplierPane {
  .criteria {
    color: $gray-sec;
    font-style: italic;
    margin: 24px 0;
  }

  .supplierColors {
    .row {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
