const context = require.context('.', false, /\.vue$/)
const icons = context.keys().reduce((acc, key) => {
  const name = key.replace('./', '').replace('.vue', '')
  const component = context(key).default
  return {
    ...acc,
    ...{ [name]: component }
  }
}, {})

export default icons
