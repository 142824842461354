<template>
  <div class="BomReviewSection">
    <h4>{{ label }}</h4>
    <BomReviewComponent
      v-for="component in sortedComponents"
      :key="component.subsection"
      :component="component"
    />
  </div>
</template>

<script>
import BomReviewComponent from './BomReviewComponent.vue'

export default {
  components: {
    BomReviewComponent
  },
  inject: [ 'store' ],
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    label () {
      const label = this.store.codes.sections[this.section.sectionName]
      return label || this.section.sectionName
    },
    sortedComponents () {
      const copy = [...this.section.components]
      // sortBySubsection is specific to a section
      return copy.sort(this.store.bomEditor.sortBySubsection(this.section.sectionName))
    }
  }
}
</script>

<style lang="scss">
.BomReviewSection {
  h4 {
    font-size: 2rem;
    color: $gray-sec;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
</style>
