<template>
  <div class="PC9EditorHeader page">
    <PC9EditorHeader />

    <BomEditor mode="pc9Edit" />
  </div>
</template>

<script>
import BomEditor from '../components/BomEditor/BomEditor.vue'
import PC9EditorHeader from '../components/BomEditor/PC9EditorHeader.vue'

export default {
  components: {
    BomEditor,
    PC9EditorHeader
  }
}
</script>

<style lang="scss">
.PC9EditorHeader.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
</style>
