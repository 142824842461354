<template>
  <div class="Search page">
    <SearchHeader />
    <Scroller>
      <BomSearch />
    </Scroller>
  </div>
</template>

<script>
import BomSearch from '../components/BomSearch/BomSearch.vue'
import Scroller from '../components/Scroller.vue'
import SearchHeader from '../components/BomSearch/SearchHeader.vue'

export default {
  components: {
    BomSearch,
    Scroller,
    SearchHeader
  }
}
</script>

<style lang="scss">
.Search.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
