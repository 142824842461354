<template>
  <div class="BomReviewSidebar">
    <div
      v-for="section in sections"
      :key="section.sectionName"
    >
      <BomReviewSidebarSection
        :section="section"
      />
    </div>
  </div>
</template>

<script>
import BomReviewSidebarSection from './BomReviewSidebarSection.vue'

export default {
  components: {
    BomReviewSidebarSection
  },
  inject: [ 'store' ],
  computed: {
    sections () {
      return this.store.codes.sectionOptions
    }
  }
}
</script>

<style lang="scss">
.BomReviewSidebar {
  border-right: 1px solid $gray-lines;
}
</style>
