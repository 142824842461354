<template>
  <div
    v-if="store.chooseMaterialCode.isLoading"
    class="ChooseCodeResultsList"
  >
    <Spinner class="stretch" />
  </div>
  <div
    v-else-if="store.chooseMaterialCode.isEmpty"
    class="ChooseCodeResultsList"
  >
    No matching materials found.
  </div>
  <div
    v-else-if="store.chooseMaterialCode.isError"
    class="ChooseCodeResultsList error"
  >
    Sorry. An error occurred trying to find your material.
    Please try a different search or contact support.
  </div>
  <div
    v-else-if="total > 0"
    class="ChooseCodeResultsList"
  >
    <MiniListHeader
      :on-previous-click="onPreviousClick"
      :on-next-click="onNextClick"
      :total="total"
      :start="start"
      :end="end"
    />

    <ul class="materialCodesList">
      <li
        v-for="result in results"
        :key="result.lscoMaterialCode"
        class="result"
        @click="onResultClick(result)"
      >
        {{ result.materialName }}
      </li>
    </ul>
  </div>
</template>

<script>
import MiniListHeader from '../MiniListHeader.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    MiniListHeader,
    Spinner
  },
  inject: [ 'store' ],
  computed: {
    end () {
      const { chooseMaterialCode } = this.store
      const pageEnd = chooseMaterialCode.skip + chooseMaterialCode.pageSize + 1
      return Math.min(pageEnd, this.total)
    },
    results () {
      return this.store.chooseMaterialCode.chooseCodeResults.results
    },
    start () {
      return this.store.chooseMaterialCode.skip + 1
    },
    total () {
      return this.store.chooseMaterialCode.chooseCodeResults.totalResults
    }
  },
  methods: {
    onNextClick () {
      this.store.chooseMaterialCode.gotoNextPage()
    },
    onPreviousClick () {
      this.store.chooseMaterialCode.gotoPreviousPage()
    },
    onResultClick (result) {
      const { findMaterial, chooseMaterialCode } = this.store
      findMaterial.material = result.lscoMaterialCode
      chooseMaterialCode.reset()
      findMaterial.nextPane()
    }
  }
}
</script>

<style lang="scss">
.ChooseCodeResultsList {
  .materialCodesList {
    margin-top: 1rem;
    border: 1px solid $gray;
    border-radius: 4px;
    overflow: hidden;

    .result {
      cursor: pointer;
      padding: 0.25rem 0.5rem;

      &:hover {
        color: $blue;
        background-color: $gray-lines;
      }
    }
  }
}
</style>
