<template>
  <div class="BomReview">
    <BomReviewSidebar />
    <BomReviewList />
  </div>
</template>

<script>
import BomReviewList from './BomReviewList.vue'
import BomReviewSidebar from './BomReviewSidebar.vue'

export default {
  components: {
    BomReviewList,
    BomReviewSidebar
  },
  inject: ['store'],
  mounted () {
    this.store.clickStream.obEvent(
      'Mode',
      'Review Mode'
    )
  }
}
</script>

<style lang="scss">
.BomReview {
  display: flex;
  align-items: stretch;
  width: 90vw;
  height: 80vh;

  .BomReviewSidebar {
    flex-basis: 280px;
  }

  .BomReviewSections {
    flex-grow: 2;
  }

  overflow-y: hidden;
}
</style>
