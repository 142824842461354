<template>
  <ContextMenu
    class="DevBomEditHeaderMenu"
    :x-offset="-25"
    :y-offset="16"
    :arrow-offset="150"
    :force-below="true"
  >
    <template v-slot:button>
      <span>Development BOM</span>
      <ChevronDownIcon />
    </template>

    <ul>
      <li>
        <ModalLink
          class="assignLink"
          name="assign-bom-choose-enter-or-lookup"
          :params="assignBomParams"
        >
          Assign to PC9s
        </ModalLink>
      </li>
      <li>
        <a
          class="renameLink"
          @click="onRenameClick"
        >
          Rename
        </a>
      </li>
      <hr>
      <li>
        <ModalLink
          class="deleteLink"
          name="delete-devbom"
          :params="deleteBomParams"
        >
          Delete
        </ModalLink>
      </li>
    </ul>
  </ContextMenu>
</template>

<script>
import ChevronDownIcon from '../icons/ChevronDownIcon.vue'
import ContextMenu from '../ContextMenu.vue'
import ModalLink from '../ModalLink.vue'

export default {
  components: {
    ChevronDownIcon,
    ContextMenu,
    ModalLink
  },
  inject: [ 'store' ],
  computed: {
    assignBomParams () {
      return {
        bomId: this.bom.bompartId
      }
    },
    bom () {
      return this.store.bomEditor.results.BOMResults[0] || {}
    },
    deleteBomParams () {
      return {
        boms: [ this.bom ],
        redirect: '/dev-boms'
      }
    }
  },
  methods: {
    onRenameClick () {
      this.store.devBomRename.edit(this.bom.bompartId)
    }
  }
}
</script>

<style lang="scss">
.DevBomEditHeaderMenu {
  .buttonWrapper {
    cursor: pointer;

    svg {
      margin-left: 1rem;
    }
  }
}
</style>
